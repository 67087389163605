import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

// const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
//   const [isOpen, setOpen] = useState(false);
//   const toggleRef = useRef(null);
//   useEffect(() => {
//     if (isOpen) {
//       document.body.classList.add('overlay-open');
//       console.log("isOpen: ", isOpen);
//     } else {
//       document.body.classList.remove('overlay-open');
//       console.log("isOpen: ", isOpen);
//     }
//   }, [isOpen]);
//   useEffect(() => {
//     console.log("toggleRef: ", toggleRef)
//     const handleClickOutside = (event) => {
//       if (toggleRef.current && !toggleRef.current.contains(event.target)) {
//         setOpen(false);
//       }
//     };
//     document.addEventListener('click', handleClickOutside);
//     return () => {
//       document.removeEventListener('click', handleClickOutside);
//     };
//   }, [toggleRef]);

//   return (
//     <a
//       href=""
//       ref={(el) => {
//         toggleRef.current = el;
//         ref(el);
//       }}
//       onClick={(e) => {
//         e.preventDefault();
//         onClick(e);
//         setOpen(!isOpen);
//       }}
//       style={{ display: "flex", alignItems: "center" }}
//     >
//       <span style={{ display: "inline-block" }}><Hamburger toggled={isOpen} size={24} /></span>
//       <span style={{ display: "inline-block", textAlign: "center" }}>{children}</span>
//     </a>
//   );
// });

// const CustomMenuToggle = React.forwardRef(({ children, onClick }, ref) => {
//   const [isOpen, setOpen] = useState(false);
//   const toggleRef = useRef(null);
//   useEffect(() => {
//     if (isOpen) {
//       document.body.classList.add('overlay-open');
//       console.log("isOpen: ", isOpen);
//     } else {
//       document.body.classList.remove('overlay-open');
//       console.log("isOpen: ", isOpen);
//     }
//   }, [isOpen]);
//   useEffect(() => {
//     console.log("toggleRef: ", toggleRef)
//     const handleClickOutside = (event) => {
//       if (toggleRef.current && !toggleRef.current.contains(event.target)) {
//         setOpen(false);
//       }
//     };
//     document.addEventListener('click', handleClickOutside);
//     return () => {
//       document.removeEventListener('click', handleClickOutside);
//     };
//   }, [toggleRef]);

//   return (
//     <a
//       href=""
//       ref={(el) => {
//         toggleRef.current = el;
//         ref(el);
//       }}
//       onClick={(e) => {
//         e.preventDefault();
//         onClick(e);
//         setOpen(!isOpen);
//       }}
//       style={{ display: "flex", alignItems: "center" }}
//     >
//       <span style={{ display: "inline-block", textAlign: "center" }}>{children}</span>
//     </a>
//   );
// });

// const CustomMenu = React.forwardRef(
//   ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
//     const [value, setValue] = useState('');

//     return (
//       <div
//         ref={ref}
//         //style={{ ...style, marginTop: 0 }}
//         className={`customFullMenu ${className}`}
//         aria-labelledby={labeledBy}
//       >
//         {/* <Form.Control
//           autoFocus
//           className="mx-3 my-2 w-auto"
//           placeholder="Type to filter..."
//           onChange={(e) => setValue(e.target.value)}
//           value={value}
//         /> */}
//         <ul className="list-unstyled">
//           {React.Children.toArray(children).filter(
//             (child) =>
//               !value || child.props.children.toLowerCase().startsWith(value),
//           )}
//         </ul>
//       </div>
//     );
//   },
// );

// <Dropdown>
//   <div className='main-menu'><nav><ul><li>
//     <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
//       <a>MENÜ</a>
//     </Dropdown.Toggle>
//   </li></ul></nav></div>
//   <Dropdown.Menu as={CustomMenu}>
//     <div className='full-menu'>
//       <ul>
//         <li>
//           <Link to={{
//             pathname: `${process.env.PUBLIC_URL}/sanitaer.html`,
//             state: [`BAD`, `11`]
//           }}>
//             <div>BAD</div>
//           </Link>
//         </li>
//         <li>
//           <Link to={{
//             pathname: `${process.env.PUBLIC_URL}/kueche.html`,
//             state: [`KÜCHE`, `153`]
//           }}>
//             <div>KÜCHE</div>
//           </Link>
//         </li>
//         <li>
//           <Link to={{
//             pathname: `${process.env.PUBLIC_URL}/heizung-2.html`,

//             state: [`HEIZUNG`, `10`]
//           }}>
//             <div>HEIZUNG</div>
//           </Link>
//         </li>
//         <li>
//           <Dropdown drop="right">
//             <Dropdown.Toggle as={CustomMenuToggle}>
//               <span>CLICK ME</span>
//             </Dropdown.Toggle>
//             <Dropdown.Menu as={CustomMenu} align={{ end: 'end' }}>
//               <div className='full-menu'>
//                 <ul>
//                   <li>
//                     <Link to={{
//                       pathname: `${process.env.PUBLIC_URL}/sanitaer.html`,
//                       state: [`BAD`, `11`]
//                     }}>
//                       <div>BAD</div>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to={{
//                       pathname: `${process.env.PUBLIC_URL}/kueche.html`,
//                       state: [`KÜCHE`, `153`]
//                     }}>
//                       <div>KÜCHE</div>
//                     </Link>
//                   </li>
//                 </ul>
//               </div>
//             </Dropdown.Menu>
//           </Dropdown>
//         </li>
//         <li>
//           <Link to={{
//             pathname: `${process.env.PUBLIC_URL}/heizung-2.html`,

//             state: [`HEIZUNG`, `10`]
//           }}>
//             <div>HEIZUNG</div>
//           </Link>
//         </li>
//         <li>
//           <Link to={{
//             pathname: `${process.env.PUBLIC_URL}/heizung-2.html`,

//             state: [`HEIZUNG`, `10`]
//           }}>
//             <div>HEIZUNG</div>
//           </Link>
//         </li>
//       </ul>
//     </div>
//     {/* <Dropdown.Item eventKey="1">Red-Orange</Dropdown.Item> */}
//   </Dropdown.Menu>
// </Dropdown>

import React, { useState, useEffect, useRef } from 'react';
import { getTheliaToken } from "../../helpers/session.js";
import { Divide as Hamburger } from 'hamburger-react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import '../../assets/icons/fontawesome';
import { API_PRODUCT_DELIVERY_DELAY, CONTENT_API, CATEGORIES_API } from "../../helpers/product";
import { useTranslation } from "react-i18next";
import { useGetConfigQuery } from '../../redux/service/ioonsService.js';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';
import { useGetCategoriesHierarchyQuery, useGetCategoriesQuery } from '../../redux/service/fastApiService.js';

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     // the translations
//     // (tip move them in a JSON file and import them,
//     // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
//     resources: {
//       en: {
//         translation: {
//           "MENÜ": "MENU"
//         }
//       }
//     },
//     lng: language, // if you're using a language detector, do not define the lng option
//     //fallbackLng: "en",
//     debug: true,
//     interpolation: {
//       escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
//     }
//   });

function FullMenu() {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openSubMenu2, setOpenSubMenu2] = useState(false);
  const [openSubMenu3, setOpenSubMenu3] = useState(false);
  const dropdownRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [fullMenuHeight, setFullMenuHeight] = useState(0);
  const [fullMenuWidth, setFullMenuWidth] = useState(0);
  const { data: externalApiCategoriesCount, isLoading: isLoadingCount } = useGetCategoriesQuery({}, {});
  const { data: externalApiCategories, isLoading: isLoadingCat } = useGetCategoriesHierarchyQuery({}, {});
  const handleButtonClick = () => {
    setOpen(!open);
    setOpenSubMenu(false);
    setOpenSubMenu2(false);
    setOpenSubMenu3(false);
  };

  const handleSubmenuClick = (index) => {
    //setOpenSubMenu(true);
    setOpenSubMenu(index);
    if (openSubMenu !== index) {
      setOpenSubMenu2(false);
      setOpenSubMenu3(false);
    }
  };

  const handleSubmenu2Click = (index) => {
    //setOpenSubMenu2(true);
    setOpenSubMenu2(index);
    if (openSubMenu2 !== index) {
      setOpenSubMenu3(false);
    }
  };

  const handleSubmenu3Click = (index) => {
    //setOpenSubMenu2(true);
    setOpenSubMenu3(index);
  };

  const [contentArray, setContentArray] = useState([]);
  const [hardCodedLinks, setHardcodedLinks] = useState([]);
  const [categoryIconObject, setCategoryIconObject] = useState({});

  useEffect(() => {
    /*
        API_PRODUCT_DELIVERY_DELAY.getContent().then((data) => {
          if (data.left_menu_content_ids) {
            data.left_menu_content_ids.split(',').forEach(number => {
              CONTENT_API.getContent(number).then((response) => {
                if (response.id == number) {
                  const newObject = { title: response.title, id: response.id, url: response?.rewriting_url?.url };
                  setContentArray(prevArray => [...prevArray, newObject]);
                }
              });
            });
          }
          if (data.category_icon)
            setCategoryIconObject(data.category_icon);
          if (data.links_left_menu)
            setHardcodedLinks(data.links_left_menu);
        })
    */
    // add when mounted
    document.addEventListener('mousedown', handleClickOutside);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const { data: configApi, isSuccess } = useGetConfigQuery();

  useEffect(() => {
    if (isSuccess) {
      if (configApi.left_menu_content_ids) {
        configApi.left_menu_content_ids.split(',').forEach(number => {
          CONTENT_API.getContent(number).then((response) => {
            if (response.id == number) {
              const newObject = { title: response.title, id: response.id, url: response?.rewriting_url?.url };
              setContentArray(prevArray => [...prevArray, newObject]);
            }
          });
        });
      }
      if (configApi.category_icon)
        setCategoryIconObject(configApi.category_icon);
      if (configApi.links_left_menu)
        setHardcodedLinks(configApi.links_left_menu);
    }
  }, [configApi]);

  function getScrollbarWidth() {
    // Create a div with a defined width and height, and overflow
    const scrollDiv = document.createElement('div');
    scrollDiv.style.width = '100px';
    scrollDiv.style.height = '100px';
    scrollDiv.style.overflow = 'scroll';
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    document.body.appendChild(scrollDiv);

    // Measure the scrollbar width
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    // Remove the div from the DOM
    document.body.removeChild(scrollDiv);

    return scrollbarWidth;
  }

  const [chatWidgetMarginAtOpen, setChatWidgetMarginAtOpen] = useState(0);
  useEffect(() => {
    const scrollbarWidth = getScrollbarWidth();
    if (dropdownRef.current) {
      setFullMenuHeight(dropdownRef.current.clientHeight);
      setFullMenuWidth(dropdownRef.current.clientWidth);
    }
    if (open) {
      document.body.classList.add('overlay-open');
      document.body.style.paddingRight = `${scrollbarWidth}px`; // Add padding to compensate for scrollbar
      document.body.style.overflow = 'hidden'; // Disable scrolling
      // Add padding-right to .sticky-bar.stick div
      const stickyBar = document.querySelector('.sticky-bar.stick');
      if (stickyBar) {
        stickyBar.style.paddingRight = `${scrollbarWidth}px`;
      }
      const scrollTop = document.querySelector('.scroll-top.show');
      if (scrollTop) {
        scrollTop.style.marginRight = `${scrollbarWidth}px`;
      }
      const launcherIframe = document.getElementById("launcher");
      if (launcherIframe) {
        // const launcherMarginRight = parseFloat(window.getComputedStyle(launcherIframe).marginRight);
        // setChatWidgetMarginAtOpen(launcherMarginRight);
        // launcherIframe.style.marginRight = `${launcherMarginRight + scrollbarWidth}px`;
      }
      const svBadges = document.querySelectorAll("#sv-vbadge-box");
      svBadges.forEach(svBadge => {
        svBadge.style.marginRight = `${scrollbarWidth}px`;
      });
      const svBadge = document.getElementById("sv-vbadge-box");
      if (svBadge) {
        svBadge.style.marginRight = `${scrollbarWidth}px`;
      }
      const svBadgePanel = document.getElementById("sv-panel-box");
      if (svBadgePanel) {
        svBadgePanel.style.marginRight = `${scrollbarWidth}px`;
      }
      // Dynamically find elements with class name "_t53mel" and apply styling
      const elementsWithT53melClass = document.querySelectorAll('._t53mel');
      elementsWithT53melClass.forEach(element => {
        element.style.setProperty('margin-right', `${scrollbarWidth}px`, 'important');
      });
      // Dynamically find elements with class name "_1dx6xh9" and apply styling
      const elementsWith1dx6xh9Class = document.querySelectorAll('._1dx6xh9');
      elementsWith1dx6xh9Class.forEach(element => {
        element.style.setProperty('margin-right', `${scrollbarWidth}px`, 'important');
      });
    } else {
      document.body.classList.remove('overlay-open');
      document.body.style.paddingRight = ''; // Reset padding
      document.body.style.overflow = ''; // Re-enable scrolling
      // Reset padding-right of .sticky-bar.stick div
      const stickyBar = document.querySelector('.sticky-bar.stick');
      if (stickyBar) {
        stickyBar.style.paddingRight = '';
      }
      const scrollTop = document.querySelector('.scroll-top.show');
      if (scrollTop) {
        scrollTop.style.marginRight = '';
      }
      const launcherIframe = document.getElementById("launcher");
      if (launcherIframe) {
        // launcherIframe.style.marginRight = `${chatWidgetMarginAtOpen}px`;
      }
      const svBadges = document.querySelectorAll("#sv-vbadge-box");
      svBadges.forEach(svBadge => {
        svBadge.style.marginRight = ``;
      });
      const svBadge = document.getElementById("sv-vbadge-box");
      if (svBadge) {
        svBadge.style.marginRight = ``;
      }
      const svBadgePanel = document.getElementById("sv-panel-box");
      if (svBadgePanel) {
        svBadgePanel.style.marginRight = ``;
      }
      // Reset styling for elements with class name "_t53mel"
      const elementsWithT53melClass = document.querySelectorAll('._t53mel');
      elementsWithT53melClass.forEach(element => {
        element.style.marginRight = ``;
      });
      // Reset styling for elements with class name "_1dx6xh9"
      const elementsWith1dx6xh9Class = document.querySelectorAll('._1dx6xh9');
      elementsWith1dx6xh9Class.forEach(element => {
        element.style.marginRight = ``;
      });
    }

    // Cleanup function to reset overflow and padding on unmount or when open changes
    return () => {
      document.body.style.paddingRight = '';
      document.body.style.overflow = '';
      // Reset padding-right of .sticky-bar.stick div

      const stickyBar = document.querySelector('.sticky-bar.stick');
      if (stickyBar) {
        stickyBar.style.paddingRight = '';
      }
    };
  }, [open]);


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !hamburgerRef.current.contains(event.target)) {
      // Check if clicked target is the button or its child element
      if (event.target.closest(".button")) {
        return;
      }
      setOpen(false);
      setOpenSubMenu(false);
      setOpenSubMenu2(false);
      setOpenSubMenu3(false);
    }
  };

  const { t } = useTranslation();

  //Very important because if this is not here, we receive a big error because we will try to map over undefined.
  if (isLoadingCat || isLoadingCount) return null;
  return (
    <div className={`container ${open ? 'banner-menu-open-color' : ''}`}>
      <div ref={hamburgerRef} className='main-menu' onClick={() => handleButtonClick()}><nav><ul><li>
        <a style={{ display: "flex", alignItems: "center" }}>
          {/* ☰ */}
          <span style={{ display: "inline-block" }}><Hamburger toggled={open} size={24} /></span>
          <span style={{ display: "inline-block", textAlign: "center" }}>
            {t('MENU')}
          </span>
        </a>
      </li></ul></nav></div>
      {open && (
        <div ref={dropdownRef} className="dropdown-fullmenu">
          <ul>
            {externalApiCategories.map((item, index) => (
              externalApiCategoriesCount[item.Id]?.count > 0 && (
                item.children && item.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? (
                  <li key={item.Id}>
                    <Link to={{
                      pathname: `${process.env.PUBLIC_URL}/${item.URL}`,
                      state: [`${item.Title}`, `${item.Id}`]
                    }} onClick={() => handleButtonClick()}>
                      <span className="category-icon-n-title col-10">
                        <span className="category-icon col-2">
                          {categoryIconObject[item.Id] ? (
                            <i className={categoryIconObject[item.Id]}></i>
                          ) : (
                            <i className="icon-052-oval"></i>
                          )}
                        </span>
                        <span className="category-title col-10" style={{ letterSpacing: "0.8px", textTransform: "uppercase" }}>{item.Title}</span>
                      </span>
                    </Link>
                    <span onClick={() => handleSubmenuClick(index)} className={`category-arrow col-2 ${openSubMenu === index ? 'colorized' : ''}`}><i className={`fa ${openSubMenu === index ? 'fa-angle-double-right' : 'fa-angle-right'}`} aria-hidden="true"></i></span>
                    {openSubMenu === index &&
                      <div className="submenu my-div" style={{ minHeight: `${fullMenuHeight}px`, minWidth: `${fullMenuWidth}px` }}>
                        <ul>
                          {item.children.map((child, index) => (
                            externalApiCategoriesCount[child.Id]?.count > 0 && (
                              child.children && child.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? (
                                <li key={child.Id}>
                                  <Link to={{
                                    pathname: `${process.env.PUBLIC_URL}/${child.URL}`,
                                    state: [`${child.Title}`, `${child.Id}`]
                                  }} onClick={() => handleButtonClick()}>
                                    <span className="category-icon-n-title col-10">
                                      <span className="category-icon col-2">
                                        {categoryIconObject[child.Id] ? (
                                          <i className={categoryIconObject[child.Id]}></i>
                                        ) : (
                                          <i className="icon-052-oval"></i>
                                        )}
                                      </span>
                                      <span className="category-title col-10">{child.Title}</span>
                                    </span>
                                  </Link>
                                  <span onClick={() => handleSubmenu2Click(index)} className={`category-arrow col-2 ${openSubMenu2 === index ? 'colorized' : ''}`}><i className={`fa ${openSubMenu2 === index ? 'fa-angle-double-right' : 'fa-angle-right'}`} aria-hidden="true"></i></span>
                                  {openSubMenu2 === index &&
                                    <div className="submenu" style={{ minHeight: `${fullMenuHeight}px`, minWidth: `${fullMenuWidth}px` }}>
                                      <ul>
                                        {child.children.map((nephew, index) => (
                                          externalApiCategoriesCount[nephew.Id]?.count > 0 && (
                                            nephew.children && nephew.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? (
                                              <li key={nephew.Id}>
                                                <Link to={{
                                                  pathname: `${process.env.PUBLIC_URL}/${nephew.URL}`,
                                                  state: [`${nephew.Title}`, `${nephew.Id}`]
                                                }} onClick={() => handleButtonClick()}>
                                                  <span className="category-icon-n-title col-10">
                                                    <span className="category-icon col-2">
                                                      {categoryIconObject[nephew.Id] ? (
                                                        <i className={categoryIconObject[nephew.Id]}></i>
                                                      ) : (
                                                        <i className="icon-052-oval"></i>
                                                      )}
                                                    </span>
                                                    <span className="category-title col-10">{nephew.Title}</span>
                                                  </span>
                                                </Link>
                                                <span onClick={() => handleSubmenu3Click(index)} className={`category-arrow col-2 ${openSubMenu3 === index ? 'colorized' : ''}`}><i className={`fa ${openSubMenu3 === index ? 'fa-angle-double-right' : 'fa-angle-right'}`} aria-hidden="true"></i></span>
                                                {openSubMenu3 === index &&
                                                  <div className="submenu" style={{ minHeight: `${fullMenuHeight}px`, minWidth: `${fullMenuWidth}px` }}>
                                                    <ul>
                                                      {nephew.children.map((grandson) => (
                                                        externalApiCategoriesCount[grandson.Id]?.count > 0 && (
                                                          <Link to={{
                                                            pathname: `${process.env.PUBLIC_URL}/${grandson.URL}`,
                                                            state: [`${grandson.Title}`, `${grandson.Id}`]
                                                          }} key={grandson.Id} onClick={() => handleButtonClick()} className="product-banner">
                                                            <li className="category-without-arrow col-12">
                                                              <span className="col-10">
                                                                <span className="category-icon col-2">
                                                                  {categoryIconObject[grandson.Id] ? (
                                                                    <i className={categoryIconObject[grandson.Id]}></i>
                                                                  ) : (
                                                                    <i className="icon-052-oval"></i>
                                                                  )}
                                                                </span>
                                                                <span className="category-title col-10">{grandson.Title}</span>
                                                              </span>
                                                            </li>
                                                          </Link>
                                                        )
                                                      ))}
                                                    </ul>
                                                  </div>
                                                }
                                              </li>
                                            ) : (
                                              <Link to={{
                                                pathname: `${process.env.PUBLIC_URL}/${nephew.URL}`,
                                                state: [`${nephew.Title}`, `${nephew.Id}`]
                                              }} key={nephew.Id} onClick={() => handleButtonClick()} className="product-banner">
                                                <li className="category-without-arrow col-12">
                                                  <span className="col-10">
                                                    <span className="category-icon col-2">
                                                      {categoryIconObject[nephew.Id] ? (
                                                        <i className={categoryIconObject[nephew.Id]}></i>
                                                      ) : (
                                                        <i className="icon-052-oval"></i>
                                                      )}
                                                    </span>
                                                    <span className="category-title col-10">{nephew.Title}</span>
                                                  </span>
                                                </li>
                                              </Link>
                                            )
                                          )
                                        ))}
                                      </ul>
                                    </div>
                                  }
                                </li>
                              ) : (
                                <Link to={{
                                  pathname: `${process.env.PUBLIC_URL}/${child.URL}`,
                                  state: [`${child.Title}`, `${child.Id}`]
                                }} key={child.Id} onClick={() => handleButtonClick()} className="product-banner">
                                  <li className="category-without-arrow col-12">
                                    <span className="col-10">
                                      <span className="category-icon col-2">
                                        {categoryIconObject[child.Id] ? (
                                          <i className={categoryIconObject[child.Id]}></i>
                                        ) : (
                                          <i className="icon-052-oval"></i>
                                        )}
                                      </span>
                                      <span className="category-title col-10">{child.Title}</span>
                                    </span>
                                  </li>
                                </Link>
                              )
                            )
                          ))}
                        </ul>
                      </div>
                    }
                  </li>
                ) : (
                  <li key={item.Id}>
                    <Link to={{
                      pathname: `${process.env.PUBLIC_URL}/${item.URL}`,
                      state: [`${item.Title}`, `${item.Id}`]
                    }} onClick={() => handleButtonClick()} className="product-banner">
                      <li className="category-without-arrow col-12">
                        <span className="col-10">
                          <span className="category-icon col-2">
                            {categoryIconObject[item.Id] ? (
                              <i className={categoryIconObject[item.Id]}></i>
                            ) : (
                              <i className="icon-052-oval"></i>
                            )}
                          </span>
                          <span className="category-title col-10" style={{ letterSpacing: "0.8px", textTransform: "uppercase" }}>{item.Title}</span>
                        </span>
                      </li>
                    </Link>
                  </li>
                )
              )
            ))}
            {contentArray.map((item, index) => (
              <li key={index}>
                <Link to={{
                  pathname: `${process.env.PUBLIC_URL}/${item?.url}`,
                  state: [`${item.title}`, `${item.id}`]
                }} onClick={() => handleButtonClick()} className="product-banner">
                  <span className="category-without-arrow col-12">
                    <span className="col-10">
                      <span className="category-icon col-2">
                        {categoryIconObject[item.Id] ? (
                          <i className={categoryIconObject[item.Id]}></i>
                        ) : (
                          <i className="icon-052-oval"></i>
                        )}
                      </span>
                      <span className="category-title col-10" style={{ letterSpacing: "0.8px", textTransform: "uppercase" }}>{item.title}</span>
                    </span>
                  </span>
                </Link>
              </li>
            ))}
            {hardCodedLinks.map((link, index) => (
              <li key={index}>
                <Link to={{
                  pathname: `${process.env.PUBLIC_URL}/${link?.href}`,
                  state: [`${link.title}`]
                }} onClick={() => handleButtonClick()} className="product-banner">
                  <span className="category-without-arrow col-12">
                    <span className="col-10">
                      <span className="category-icon col-2">
                        {categoryIconObject[link.Id] ? (
                          <i className={categoryIconObject[link.Id]}></i>
                        ) : (
                          <i className="icon-052-oval"></i>
                        )}
                      </span>
                      <span className="category-title col-10" style={{ letterSpacing: "0.8px", textTransform: "uppercase" }}>{link.title}</span>
                    </span>
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

FullMenu.propTypes = {
  open: PropTypes.bool
};

export default FullMenu;
