import { useSelector } from 'react-redux';

const useLocaleConfigSelectors = () => {
	const localeConfig = useSelector((state) => state.localeConfig) || {};

	const getProp = (property, defaultValue = "") => {
		return localeConfig?.[property] ?? defaultValue;
	};

	const extractTaxPercentage = (taxTitle) => {
		if (taxTitle) {
			const match = taxTitle.match(/\d+(?=%)/);
			return match ? match[0] + '%' : "";
		} else {
			return null;
		}
	}

	return {
		getLangCode: getProp("lang_locale"),
		getCountryId: getProp("country_id"),
		getContentIds: getProp("content_ids"),
		getCurrencyCode: getProp("currency_currency_code"),
		getTaxTitle: getProp("tax_tax_title", "%"),
		getLangId: getProp("lang_id", 1),
		getLangUrl: getProp("lang_url"),
		getTaxId: getProp("tax_tax_id"),
		getTaxSerializedRequirements: getProp("tax_serialized_requirements"),
		getStoreName: getProp("store_name"),
		getLoadingGif: getProp("loading_gif"),
		getCurrencySymbol: getProp("currency_currency_symbol"),
		getSiteKey: getProp("site_key"),
		getReCaptchaSiteKey: getProp("captcha_site_key"),
		getLogoImage: getProp("logo_image"),
		getAnpc: getProp("anpc"),
		getSal: getProp("sal"),
		getDisplayANPC: getProp("display_buttons_ANPC"),
		getDefaultProductImage: getProp("default_product_image"),
		getWiderrufsformularPdfLink: getProp("widerrufsformular_pdf_link"),
		getLogoFooter: getProp("logo_footer"),
		getLogoHeader: getProp("logo_header"),
		getGuaranteeLinks: getProp("guarantee_links"),
		getAdvent: getProp("advent"),
		getAdventBg: getProp("advent_bg"),
		getAdventGift: getProp("advent_gift"),
		getHomepageProducts: getProp("homepage_products"),
		getHeroSliderTwelve: getProp("hero_slider_twelve"),
		getSiteName: getProp("site_name"),
		getVorkasseInfo: getProp("vorkasse_info"),
		getCountryTitle: getProp("country_title"),
		getTaxPercentage: extractTaxPercentage(getProp("tax_tax_title")),
		getIoonsMailPicture: getProp("ioons_mail_picture"),
		getIoonsFacebookUrl: getProp("ioons_facebook_url"),
		getIoonsInstagramUrl: getProp("ioons_instagram_url"),
		getIoonsYoutubeUrl: getProp("ioons_youtube_url"),
		getShopvoteShopId: getProp("shopvote_shop_id"),
		getFooterImage1: getProp("footer_image_1"),
		getFooterImage2: getProp("footer_image_2"),
		getFooterImage3: getProp("footer_image_3"),
		getFooterImage4: getProp("footer_image_4"),
		getFeatureIconEight: getProp("feature_icon_eight"),
		getBrandLogoOne: getProp("brand_logos_homepage"),
		getTestimonialOne: getProp("testimonial_one"),
		getShopvoteDisplay: getProp("shopvote_display", { "footer": false, "badge": false }),
		getTrustedShopDisplay: getProp('trustedshop_display', { 'footer': false, 'badge': false }),
		getHomeTeamImg: getProp("home_team_img"),
		getTrustedShopId: getProp("trusted_shop_id"),
		getPhoneRegex: getProp("phone_regex"),
		getVatMinLength: getProp("vat_min_length"),
		getTextInCart: getProp("textInCart"),
		getMultiplier: getProp("multiplier"),
		getContentWithImages: getProp("content_with_images"),
		getBestSellersProductsHomepageIds: getProp("best_sellers_products_homepage_ids"),
		getBannersHomepage: getProp("banners_homepage"),
		getCountryVisible: getProp("country_visible"),
		getConfigMinPrice: getProp("config_min_price"),
		getCountryFlag: getProp("country_flag"),
		getDisplayCountryHeader: getProp("display_country_header"),
		getIsPhoneRequired: getProp("is_phone_required"),
	};
};

export default useLocaleConfigSelectors;
