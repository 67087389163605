import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import { loadLanguages, multilanguage } from 'redux-multilanguage';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TagManager from 'react-gtm-module/dist/TagManager.js';
import config from 'react-global-configuration';
import * as Sentry from '@sentry/react';
import { APP_VERSION } from './helpers/session.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from "react-i18next";
import ScrollToTop from "./helpers/scroll-top";
import translationsRo from './assets/i18n_ioons/translations_ro.json';
import translationsDe from './assets/i18n_ioons/translations_de.json';
import { useGetConfigQuery } from './redux/service/ioonsService';
import useLocalRedux from './helpers/useLocalRedux';
import useLocaleConfigSelectors from './helpers/useLocaleConfigSelectors';
import { useZenDesk } from './helpers/UseZenDesk';
import { useGetCategoriesQuery, useGetLocaleQuery, useGetSystemAllQuery } from './redux/service/fastApiService';
import TokenProvider from './TokenProvider';
import NestedRouter from './NestedRouter';
import { setCountry } from './redux/slices/countrySlice.js';
import config_src from './config/config.js';

// home pages
const IOONSHOME = lazy(() => import("./pages/home/IOONS_HOME"));
// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
    import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
    import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
    import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));

// other pages
const AdventCalendar = lazy(() => import("./pages/other/AdventCalendar/AdventCalendar"));
const About = lazy(() => import("./pages/other/About"));
const Bezahlen = lazy(() => import("./pages/other/Bezahlen"));
const Lieferung = lazy(() => import("./pages/other/Lieferung"));
const Datenschutz = lazy(() => import("./pages/other/Datenschutz"));
const Widerrufsrecht = lazy(() => import("./pages/other/Widerrufsrecht"));
const AGB = lazy(() => import("./pages/other/AGB"));
const FAQ = lazy(() => import("./pages/other/Faq"));
const Presse = lazy(() => import("./pages/other/Presse"));
const Impressum = lazy(() => import("./pages/other/Impressum"));
const Versandkosten = lazy(() => import("./pages/other/Versandkosten"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Newsletter = lazy(() => import("./pages/other/Newsletter"));
const NewsletterUnsubscribe = lazy(() => import("./pages/other/NewsletterUnsubscribe"));
const MyAccount = lazy(() => import("./pages/other/MyAccount/MyAccount"));
const MyOrder = lazy(() => import("./pages/other/MyAccount/MyOrder"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const OrderPlaced = lazy(() => import("./pages/other/OrderPlaced"));
const OrderFailed = lazy(() => import("./pages/other/OrderFailed"));
const FaErrorPage = lazy(() => import("./pages/other/FaErrorPage"));
const ElasticSearchPage = lazy(() => import("./pages/shop/ElasticSearchPage"));
const AbandonedCart = lazy(() => import("./pages/other/AbandonedCart"));

// const language = navigator.language.split("-")[0];
const language = config.get('language');
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            de: {
                translation: translationsDe
            },
            ro: {
                translation: translationsRo
            }
        },
        lng: language, // if you're using a language detector, do not define the lng option
        // fallbackLng: "en",
        // debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

function App(props) {
    const [initStore, setInitStore] = useState(0);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useGetConfigQuery({}, { refetchOnMountOrArgChange: true });
    const { error: systemError, isSuccess: isSuccessSystemAll} = useGetSystemAllQuery();
    const {isSuccess: isSuccessLocale} = useLocalRedux();
    useZenDesk();
    const { getWiderrufsformularPdfLink, getCountryVisible: countryDropdownInfo, getCurrencyCode, getCurrencySymbol } = useLocaleConfigSelectors();
    const { data: externalApiCategoriesCount, error: categoriesError, isSuccess: isSuccessCategories } = useGetCategoriesQuery({}, { refetchOnMountOrArgChange: true });
    
    const handleToast = () => t('Our website has been updated');


    useEffect(() => {
        if (getCurrencyCode !== null || getCurrencySymbol !== null) {
            const currencyInfo = {
                currencySymbol: getCurrencySymbol,
                currencyName: getCurrencyCode,
                currencyRate: 1,
            };

            dispatch({
                type: 'SET_CURRENCY',
                payload: currencyInfo,
            });
        }
    }, [getCurrencyCode, getCurrencySymbol]);

    const [width] = useWindowSize();
    const tagManagerArgs = {
        gtmId: config.get('gtmId')
    }
    const { data: configApi, isSuccess } = useGetConfigQuery();
    const [displayAdvent, setDisplayAdvent] = useState();
    useEffect(() => {
        if (isSuccess) {
            if (configApi.displayAdvent) {
                setDisplayAdvent(configApi.displayAdvent);
            }
        }
    }, [configApi]);

    useEffect(() => {
        if (initStore) {
            invalidateLocalStorage();
        }
    }, [initStore]);

    function invalidateLocalStorage() {
        const currentLoc = localStorage.getItem("lastLocation");
        localStorage.clear();
        if (window.location.pathname !== '/') {
            window.location.href = '/';
            window.location.href = currentLoc;
        }
        else
            window.location.reload();
        localStorage.setItem('app_version', APP_VERSION);
        localStorage.setItem('show_new_version_toast', 1);
    }

    useEffect(() => {
        const isNewUser = localStorage.getItem("new_user");

        if (!isNewUser) {
            localStorage.setItem("new_user", "new_user");
            const timeout = setTimeout(() => {
                window.location.reload();
            }, 1000);

            return () => {
                clearTimeout(timeout);
            };
        }
        if (localStorage.getItem('app_version') === null) {
            localStorage.setItem('app_version', APP_VERSION);
        }
        const storedVersion = localStorage.getItem('app_version');
        if (localStorage.getItem('show_new_version_toast')) {
            const toastMessage = handleToast();
            const toastId = toast.info(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: '10px', marginBottom: 0 }}>{toastMessage}</p>
                    <button className="update-version-button theme-color-hover-background-hover" onClick={() => { toast.dismiss(toastId); }}>OK</button>
                </div>
                , { pauseOnHover: false, draggable: false, pauseOnFocusLoss: false, closeOnClick: false, position: toast.POSITION.TOP_RIGHT, autoClose: 15000 }
            );
            localStorage.removeItem('show_new_version_toast');
        }
        if (storedVersion !== APP_VERSION) {
            const timer = setTimeout(() => {
                setInitStore(1);
            }, 0);
            return () => {
                clearTimeout(timer);
                toast.dismiss(toastId);
            };
        }
        Sentry.init({
            dsn: "https://1dc086ee2c634ba28fd64045aeba72e0@o4505068489867264.ingest.sentry.io/4505068497797120",
            integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        TagManager.initialize(tagManagerArgs);

        props.dispatch(
            loadLanguages({
                languages: {
                    de: require("./assets/i18n_ioons/translations_de.json"),
                    ro: require("./assets/i18n_ioons/translations_ro.json")
                }
            })
        );
    }, []);
    useEffect(() => {
        if (externalApiCategoriesCount) {
            localStorage.setItem("categories_id_list", JSON.stringify(externalApiCategoriesCount));
        }
    }, [externalApiCategoriesCount])

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }

            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }
  
  let loadingStatus = <>
    <div className="flone-preloader-wrapper">
      <div className="flone-preloader">
        <span />
        <span />
      </div>
    </div>
  </>;
    
    const checkIfClassExists = () => {
        const element = document.querySelector('.popup-google-content-cookie-consent');
        if (element) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }
    useEffect(() => {
        setTimeout(checkIfClassExists, 500);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
      <TokenProvider>
      <ToastProvider placement={width > 767 ? "top-right" : "top-center"}>
            <BreadcrumbsProvider>
                <Router>
                    <ScrollToTop>
                        <Suspense
                            fallback={
                                loadingStatus
                            }
                        >
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/`}
                                    component={categoriesError || systemError ? FaErrorPage : IOONSHOME}
                                />

                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/advent-calendar`}
                                    component={displayAdvent == 1 ? AdventCalendar : NotFound}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/shop-grid-standard/:type`}
                                    component={ShopGridStandard}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/shop-grid-no-sidebar/:type`}
                                    component={ShopGridNoSidebar}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product/:id`}
                                    render={(routeProps) => (
                                        <Product {...routeProps} key={routeProps.match.params.id} />
                                    )}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-tab-left/:id`}
                                    component={ProductTabLeft}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-tab-right/:id`}
                                    component={ProductTabRight}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-sticky/:id`}
                                    component={ProductSticky}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-slider/:id`}
                                    component={ProductSlider}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-fixed-image/:id`}
                                    component={ProductFixedImage}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/blog`}
                                    component={BlogStandard}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/about`}
                                    component={About}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/bezahlen`}
                                    component={Bezahlen}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/lieferung`}
                                    component={Lieferung}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/datenschutz`}
                                    component={Datenschutz}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/agb.html`}
                                    component={AGB}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/faq`}
                                    component={FAQ}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/presse`}
                                    component={Presse}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/Impressum`}
                                    component={Impressum}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/widerrufsrecht`}
                                    component={Widerrufsrecht}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/contact`}
                                    component={Contact}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/newsletter`}
                                    component={Newsletter}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/newsletter-unsubscribe`}
                                    component={NewsletterUnsubscribe}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/account/order/:id`}
                                    component={MyOrder}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/account`}
                                    component={MyAccount}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/guest/invoice/:ref/:hash`}
                                    component={MyOrder}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/versandkosten`}
                                    component={Versandkosten}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/login-register`}
                                    component={LoginRegister}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/forgot-password`}
                                    component={ForgotPassword}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/cart`}
                                    component={Cart}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/wishlist`}
                                    component={Wishlist}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/compare`}
                                    component={Compare}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/checkout`}
                                    component={Checkout}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/order-placed`}
                                    component={OrderPlaced}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/order-failed`}
                                    component={OrderFailed}
                                />

                                <Route
                                    path="/widerrufsformular.pdf"
                                    render={() => { window.location.href = getWiderrufsformularPdfLink }}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/not-found`}
                                    component={NotFound}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/search/:value`}
                                    component={ElasticSearchPage}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/back-to-cart/:token`}
                                    component={AbandonedCart}
                                />

                                <Route path={`${process.env.PUBLIC_URL}/*`}
                                    component={NestedRouter}
                                />
                            </Switch>
                        </Suspense>
                    </ScrollToTop>
                </Router>
            </BreadcrumbsProvider>
            <ToastContainer />
        </ToastProvider>
        </TokenProvider>
    );
};

App.propTypes = {
    dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
