import { useEffect } from 'react';
import config from 'react-global-configuration';
import config_src from '../config/config';
import { useDispatch } from 'react-redux';
import { setLocaleConfig } from '../redux/slices/locale_configSlice';
import { useGetLocaleQuery } from '../redux/service/fastApiService';
import { setCountry } from '../redux/slices/countrySlice.js';
import useLocaleConfigSelectors from './useLocaleConfigSelectors.js';

const useLocalRedux = () => {
  const dispatch = useDispatch();
  
	const fetch = useGetLocaleQuery(
		{},
		{
			refetchOnMountOrArgChange: config_src.refetchTimingLong,
		}
	);
  const { data: response, error, isError, isSuccess } = fetch;
  const {getTaxTitle, getCountryId, getCountryTitle} = useLocaleConfigSelectors();

	useEffect(() => {
		if (isError && error) {
			console.error('Error:', error);
		}
		if (isSuccess && response) {
			const extractedKeys = Object.keys(response)
				.filter(key => config.get('locale_config').includes(key))
				.map(key => ({
					key,
					value:
						key === 'tax_serialized_requirements'
							? JSON.parse(atob(response[key]))
							: response[key],
				}));

			const extractedKeyValuePairs = extractedKeys.reduce(
				(accumulator, { key, value }) => {
					accumulator[key] = value;
					return accumulator;
				},
				{}
			);
			const { store_name, lang_code } = extractedKeyValuePairs;
			if (
				store_name &&
				lang_code
			) {
				extractedKeyValuePairs.site_name = `${store_name}.${lang_code}`.toUpperCase();
			}
			// Dispatch the action to save the config in the redux store
			dispatch(setLocaleConfig(extractedKeyValuePairs));
      localStorage.setItem('locale_config', JSON.stringify(extractedKeyValuePairs));
		}
	}, [response, error, dispatch]);
  
  
  useEffect(() => {
  	
  	if(localStorage.getItem('excludeVAT') != 'true' ) {
	    if (getTaxTitle && getCountryId && getCountryTitle) {
	      const countryObject = {
	        id: getCountryId,
	        title: getCountryTitle,
	        tax_title: getTaxTitle,
	      };
	      // console.log("Setting a new country object in the redux store", countryObject);
	      dispatch(setCountry(countryObject));
	      localStorage.setItem("country_tax", countryObject.tax_title);
	      localStorage.setItem("country_id", countryObject.id);
    	} 
	}
  }, [getTaxTitle, getCountryId, getCountryTitle, dispatch])
  
  return fetch;
};
export default useLocalRedux;
