import { statement } from "@babel/template";
import PropTypes from "prop-types";
import React, { Fragment, useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import { Ellipsis } from "react-awesome-spinners";
import { getDiscountPrice } from "../../../helpers/product";
import { fetchToken } from '../../../helpers/session.js';
import { API, getSessionCartId, getSessionDeliveryId, checkSessionDeliveryId, getCustomerId } from "../../../redux/api/cart.js";
import useLocaleConfigSelectors from '../../../helpers/useLocaleConfigSelectors.js';
import { useGetCouponMutation } from '../../../redux/service/ioonsService.js';
import { useSelector } from 'react-redux';

function MenuCart({ cartData, currency, deleteFromCart, shopCartValue, refOfIcon }) {

  const { getLogoImage, getTaxTitle } = useLocaleConfigSelectors();
  // const { tax_title: getSelCountryTax , id, title} = useSelector((state) => state.country?.selectedCountry);
  const data = useSelector((state) => state.country?.selectedCountry);
  const getSelCountryTax = data?.tax_title;
  const isCheckoutPage = window.location.pathname === `${PUBLIC_URL  }/checkout`;
  const { t } = useTranslation();

  const taxTitle = localStorage.getItem('excludeVAT') === 'true'
      ? `(${t('VAT 0%')})`
      : `${t('incl')} ${getSelCountryTax || getTaxTitle}`;

  let cartTotalPrice = 0;
  const { addToast } = useToasts();

  const loginCartHeader = () => {
    localStorage.setItem("lastLocation", "/cart");
  }

  const [clicked, setClicked] = useState(false);

  const refOne = useRef(null)

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (refOne?.current?.contains(e.target) || refOfIcon?.current?.contains(e.target)) {
        setClicked(true);
      }
      else {
        setClicked(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener("click", handleClickOutside);
    };
  }, [clicked])
  const [shippingCost, setShippingCost] = useState(0);

  useEffect(() => {
    if (getSessionCartId() != 0 && cartData && cartData.length > 0) {
      setLoadingShippingCost(true);
      const defaultDeliveryId = checkSessionDeliveryId();
      API.getDeliveryCosts(defaultDeliveryId).then((result) => {
        if (result.error !== "Invalid data") {
          let totalShippingCost;
          let shippingCostRes = Number(result.postage_amount).toFixed(2);
          let shippingCostTaxedRes = Number(result.postage_amount_taxed).toFixed(2);
          if(localStorage.getItem('excludeVAT') === 'true') {
            totalShippingCost = shippingCostRes - shippingCostTaxedRes;
          }else {
            totalShippingCost = shippingCostRes;
          }
          const total = Number(totalShippingCost);
          setShippingCost(total);
          setLoadingShippingCost(false);
        } else {
          // addToast("Something went wrong", { appearance: 'error', autoDismiss: true });
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [cartData, getSessionCartId(), getSessionDeliveryId()]);

  const couponName = localStorage.getItem('appliedCoupon');
  const [couponCode] = useState(couponName);
  const [getCoupon, { data: coupon, isError: isErrorCoupon, error, isLoading: isLoadingCoupon, isSuccess: isSuccessCoupon }] = useGetCouponMutation();
  const payload = {
    coupon_code: couponCode,
    cart_id: getSessionCartId(),
    customer_id: getCustomerId(),
  };
  useEffect(() => {
    fetchCouponData();
  }, [payload.customer_id]);
  const fetchCouponData = async () => {
    if (!couponCode) return;
    try {
      // if (!payload.customer_id) {
      //     addToast(t('Please login again.'), { appearance: 'error', autoDismiss: true });
      // }
      await getCoupon(payload);
    } catch (e) {
      console.error("An error occurred while fetching the coupon:", e);
    }
  };
  useEffect(() => {
    if (couponCode) {
      fetchCouponData();
    }
  }, [couponCode]);
  const isCouponValid = isSuccessCoupon && coupon.status === 'success';

  const appliedCoupon = localStorage.getItem('appliedCoupon');
  const appliedCouponDiscount = parseFloat(localStorage.getItem('appliedCouponDiscount'));

  const [loadingShippingCost, setLoadingShippingCost] = useState(true);
    const { PUBLIC_URL } = process.env;
    return (
    <div className={(clicked) ? "shopping-cart-content active" : "shopping-cart-content"} ref={refOne}>
      {cartData && cartData.length > 0 ? (
        <>
          <ul>
            {cartData.map((single, key) => {
              if (single.TAXED_PRICE != null)
                if (Number(single?.IS_PROMO) === 1)
                  if (localStorage.getItem('excludeVAT') === 'true') {
                    cartTotalPrice += single.PROMO_PRICE * single.quantity
                  } else {
                    cartTotalPrice += single.TAXED_PROMO_PRICE * single.quantity
                  }
                else
                  if (localStorage.getItem('excludeVAT') === 'true') {
                    cartTotalPrice += single.PRICE * single.quantity
                  } else {
                    cartTotalPrice += single.TAXED_PRICE * single.quantity
                  }
              else
                cartTotalPrice += single.LISTEN_PRICE * single.quantity
              const urlProduct = PUBLIC_URL + single.URL.substr(single.URL.lastIndexOf('/') + 1);
                const src = single?.IMAGE
                    ? single.IMAGE
                    : single.image.length > 0
                        ? PUBLIC_URL + single.image[0]
                        : getLogoImage;
                return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={urlProduct}>
                      <img
                        alt=""
                        src={src}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      {/* <Link
                        to={PUBLIC_URL + "/product/" + single.id}
                      >
                        {" "}
                        {single.TITLE}{" "}
                      </Link> */}
                      <a href={urlProduct}>
                        {single.TITLE}
                      </a>
                    </h4>
                    <h6>
                      {single.TAXED_PRICE !== "" ? (
                        <span>
                          {single.quantity}
                          <div className="red-text"> x </div>
                          {localStorage.getItem('excludeVAT') === 'true' ?
                            (Number(single?.IS_PROMO) === 1 ? Number(single.PROMO_PRICE).toFixed(2) : Number(single.PRICE).toFixed(2))
                            :
                            (Number(single?.IS_PROMO) === 1 ? single.TAXED_PROMO_PRICE.toFixed(2) : single.TAXED_PRICE.toFixed(2))
                          }
                          {' '}{currency.currencySymbol}
                        </span>
                      ) : (
                        <>
                          {single.quantity}
                          <div className="red-text"> x </div>
                          {single.LISTEN_PRICE.toFixed(2)}
                          {' '}{currency.currencySymbol}
                        </>
                      )}
                    </h6>
                    
                  </div>
                  <div className="shopping-cart-delete">
                    {!isCheckoutPage &&
                      <button onClick={() => deleteFromCart(single, addToast, t('Removed from cart'))}>
                        <i className="fa fa-times-circle" />
                      </button>
                    }
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <div className="left">
              {t('Value of goods')} <p>{taxTitle}</p>
            </div>
            <div className="right">
              {`${cartTotalPrice.toFixed(2)} ${currency.currencySymbol}`}
            </div>
          </div>
          <div className="shopping-cart-total">
            <div className="left">
              {t('Shipment')}
            </div>
            <div className="right">
              {!loadingShippingCost ? (
                shippingCost == 0
                  ? t('Free')
                  : `${shippingCost.toFixed(2)} ${currency.currencySymbol}`
              ) : (
                <Ellipsis size='18' />
              )}
            </div>
          </div>
          {appliedCoupon && appliedCouponDiscount &&
            <div className="shopping-cart-total">
              <div className="left">
                {t('Discount')}{' '}
                <span className='coupon'>{appliedCoupon}</span>
              </div>
              <div className="right">
                {`-${appliedCouponDiscount.toFixed(2)} ${currency.currencySymbol}`}
              </div>
            </div>
          }
          <div className="subtotal-total-dividing" />
          <div className="shopping-cart-total">
            <div className="left">
              {t('Total')}
            </div>
            <div className="right">
              {!loadingShippingCost ? (
                appliedCouponDiscount ?
                  `${(cartTotalPrice + shippingCost - appliedCouponDiscount).toFixed(2)} ${currency.currencySymbol}`
                  :
                  `${(cartTotalPrice + shippingCost).toFixed(2)} ${currency.currencySymbol}`
              ) : (
                <Ellipsis size='18' />
              )}
            </div>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={`${PUBLIC_URL}/cart`}>
              {t('Cart')}
            </Link>

            {fetchToken() ? <Link
              className='default-btn'
              to={`${PUBLIC_URL}/checkout`}
            >
              {t('To checkout')}
            </Link> :
              <div>
                <Link
                  className='default-btn'
                  to={{
                    pathname: `${PUBLIC_URL}/login-register`,
                    state: { defaultTab: 'register' }
                  }}
                  onClick={loginCartHeader}
                >
                  {t('Register to continue')}
                </Link>
                <div className="menu-cart-login-wrapper">
                  {t('Already have an account?')}
                  <Link
                    className='menu-cart-login'
                    to={`${PUBLIC_URL  }/login-register`}
                    onClick={loginCartHeader}
                  >
                    {t('Log in')}
                  </Link>
                </div>
                <div className="divider-line divider-line-small">
                  <div className="line-sperator" />
                  <div className="line-text">{t('OR')}</div>
                  <div className="line-sperator" />
                </div>
                <Link
                  className='default-btn'
                  to={{
                    pathname: `${PUBLIC_URL  }/checkout`
                  }}
                >
                  {t('Continue as guest')}
                </Link>
              </div>
            }
          </div>
        </>
      ) : (
        <p className="text-center">{t('No items added to cart')}</p>
      )}
    </div>
  );
}

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func
};

export default MenuCart;
