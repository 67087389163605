import config from 'react-global-configuration';
import axios from 'axios';
import { fetchToken, getTheliaToken } from '../../helpers/session.js';
import { FETCH_PRODUCTS_SUCCESS } from '../actions/productActions.js';
import { FETCH_COUNTY_SUCCESS } from '../actions/countyActions.js';


const initState = {
	county: [],
};

const countyReducer = async (state = initState, action) => {
	if (action.type === FETCH_COUNTY_SUCCESS) {
		return {
			...state,
			county: action.payload
		};
	}

	return state;
};

export default countyReducer;