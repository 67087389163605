import config from "react-global-configuration";
import {getBearerToken, getTheliaToken} from "./session.js";
import configuration from "../config/config.js";

config.set(configuration);


export const BASE_API = {
    theliaApi: config.get('apiUrl'),
    fastApi: config.get('fastApi'),
    makeRequest: (url, method = 'GET', data = {}, auth = "Bearer") => {
        return fetch(url, {
            method: method,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                'Access-Control-Allow-Origin': '*',
                "Authorization": auth === "Bearer" ? `${getBearerToken()}` : `${getTheliaToken()}`,
            },
            body: method === 'POST' ? JSON.stringify(data) : undefined,
        }).then(response => response.json());
    },
}