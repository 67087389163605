// get products
import { BASE_API } from "./base_api.js";
import config from 'react-global-configuration';
import { getContentIds } from './localStorageConfig.js';
import React, { useState } from 'react';

const contentIds = getContentIds
const locale = config.get("locale");

export const getProducts = (products, category, type, limit) => {
    const finalProducts = category
        ? products.filter(
            product => product.category.filter(single => single === category)[0]
        )
        : products;

    if (type && type === "new") {
        const newProducts = finalProducts.filter(single => single.new);
        return newProducts.slice(0, limit ? limit : newProducts.length);
    }
    if (type && type === "bestSeller") {
        return finalProducts
            .sort((a, b) => {
                return b.saleCount - a.saleCount;
            })
            .slice(0, limit ? limit : finalProducts.length);
    }
    if (type && type === "saleItems") {
        const saleItems = finalProducts.filter(
            single => single.discount && single.discount > 0
        );
        return saleItems.slice(0, limit ? limit : saleItems.length);
    }
    return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

export const getListProducts = (products, category, type, limit) => {
    const finalProducts = category
        ? products.filter(
            product => product.category.filter(single => single === category)[0]
        )
        : products;

    if (type && type === "new") {
        const newProducts = finalProducts.filter(single => single.new);
        return newProducts.slice(0, limit ? limit : newProducts.length);
    }
    if (type && type === "bestSeller") {
        return finalProducts
            .sort((a, b) => {
                return b.saleCount - a.saleCount;
            })
            .slice(0, limit ? limit : finalProducts.length);
    }
    if (type && type === "saleItems") {
        const saleItems = finalProducts.filter(
            single => single.discount && single.discount > 0
        );
        return saleItems.slice(0, limit ? limit : saleItems.length);
    }
    return finalProducts.slice(0, limit ? limit : finalProducts.length);
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
    return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product discount
export const getDiscount = (oldPrice, newPrice) => {
    let discount = 0;

    // Calculate discount based on the prices
    if (oldPrice > newPrice) {
        discount = (100 - (newPrice / oldPrice) * 100);
    } else {
        discount = (100 - (oldPrice / newPrice) * 100);
    }

    // Convert to absolute value to always get a positive number
    discount = Math.abs(discount);
    return discount.toFixed(0);
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
    let productInCart = cartItems.filter(
        single =>
            single?.id === product?.id &&
            (single.selectedProductColor
                ? single.selectedProductColor === color
                : true) &&
            (single.selectedProductSize ? single.selectedProductSize === size : true)
    )[0];
    if (cartItems.length >= 1 && productInCart) {
        if (product.variation) {
            return cartItems.filter(
                single =>
                    single.id === product.id &&
                    single.selectedProductColor === color &&
                    single.selectedProductSize === size
            )[0].quantity;
        } else {
            return cartItems.filter(single => product.id === single.id)[0].quantity;
        }
    } else {
        return 0;
    }
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue) => {
    const urlParams = new URLSearchParams(window.location.search);
    let state = urlParams.get('state');
    // console.log("state: ", state);
    // if (products && state) {
    //     const filteredProducts = products.filter(product => (
    //         product.TITLE.toLowerCase().includes(state.toLowerCase()) ||
    //         product.DESCRIPTION.toLowerCase().includes(state.toLowerCase()) ||
    //         product.REF.toLowerCase().includes(state.toLowerCase()) ||
    //         product.EAN_CODE.toLowerCase().includes(state.toLowerCase())
    //     ));
    //     return filteredProducts;
    //     // return products.filter(
    //     //     product => product.category.filter(single => single === sortValue)[0]
    //     // );
    // }
    if (products && sortType && sortValue) {
        if (sortType === "category") {
            return products.filter(
                product => product.category.filter(single => single === sortValue)[0]
            );
        }
        if (sortType === "tag") {
            return products.filter(
                product => product.tag.filter(single => single === sortValue)[0]
            );
        }
        if (sortType === "color") {
            return products.filter(
                product =>
                    product.variation &&
                    product.variation.filter(single => single.color === sortValue)[0]
            );
        }
        if (sortType === "size") {
            return products.filter(
                product =>
                    product.variation &&
                    product.variation.filter(
                        single => single.size.filter(single => single.name === sortValue)[0]
                    )[0]
            );
        }
        if (sortType === "filterSort") {
            let sortProducts = [...products];
            if (sortValue === "default") {
                return sortProducts;
            }
            if (sortValue === "priceHighToLow") {
                return sortProducts.sort((a, b) => b.TAXED_PRICE - a.TAXED_PRICE);
            }
            if (sortValue === "priceLowToHigh") {
                return sortProducts.sort((a, b) => a.TAXED_PRICE - b.TAXED_PRICE);
            }
        }
    }
    return products;
};

// get individual element
const getIndividualItemArray = array => {
    let individualItemArray = array.filter(function (v, i, self) {
        return i === self.indexOf(v);
    });
    return individualItemArray;
};

// get individual categories
export const getIndividualCategories = products => {
    let productCategories = [];
    products &&
        products.map(product => {
            return (
                product.category &&
                product.category.map(single => {
                    return productCategories.push(single);
                })
            );
        });
    const individualProductCategories = getIndividualItemArray(productCategories);
    return individualProductCategories;
};
// get individual DEFAULT_CATEGORIES
export const getIndividualDefaultCategories = products => {
    let productCategories = [];
    products &&
        products.map(product => {
            return (
                productCategories.push(product.DEFAULT_CATEGORY)
            );
        });
    const individualProductCategories = getIndividualItemArray(productCategories);
    return individualProductCategories;
};

// get individual tags
export const getIndividualTags = products => {
    let productTags = [];
    products &&
        products.map(product => {
            return (
                product.tag &&
                product.tag.map(single => {
                    return productTags.push(single);
                })
            );
        });
    const individualProductTags = getIndividualItemArray(productTags);
    return individualProductTags;
};

// get individual colors
export const getIndividualColors = products => {
    let productColors = [];
    products &&
        products.map(product => {
            return (
                product.variation &&
                product.variation.map(single => {
                    return productColors.push(single.color);
                })
            );
        });
    const individualProductColors = getIndividualItemArray(productColors);
    return individualProductColors;
};

// get individual sizes
export const getProductsIndividualSizes = products => {
    let productSizes = [];
    products &&
        products.map(product => {
            return (
                product.variation &&
                product.variation.map(single => {
                    return single.size.map(single => {
                        return productSizes.push(single.name);
                    });
                })
            );
        });
    const individualProductSizes = getIndividualItemArray(productSizes);
    return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = product => {
    let productSizes = [];
    product.variation &&
        product.variation.map(singleVariation => {
            return (
                singleVariation.size &&
                singleVariation.size.map(singleSize => {
                    return productSizes.push(singleSize.name);
                })
            );
        });
    const individualSizes = getIndividualItemArray(productSizes);
    return individualSizes;
};

export const setActiveSort = e => {
    const filterButtons = document.querySelectorAll(
        ".sidebar-widget-list-left button, .sidebar-widget-tag button, .product-filter button"
    );
    filterButtons.forEach(item => {
        item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
};

export const setActiveSortDF = e => {
    const filterButtons = document.querySelectorAll(
        ".product-wrap"
    );
    filterButtons.forEach(item => {
        item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
};

export const setActiveLayout = e => {
    const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
    gridSwitchBtn.forEach(item => {
        item.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = e => {
    const shopTopFilterWrapper = document.querySelector(
        "#product-filter-wrapper"
    );
    shopTopFilterWrapper.classList.toggle("active");
    if (shopTopFilterWrapper.style.height) {
        shopTopFilterWrapper.style.height = null;
    } else {
        shopTopFilterWrapper.style.height =
            shopTopFilterWrapper.scrollHeight + "px";
    }
    e.currentTarget.classList.toggle("active");
};

//I cannot refactor this into RTK
export const API = {
    getProducts: (data) => BASE_API.makeRequest(
        `${BASE_API.theliaApi}/api/products/` +
        `${data.limit ? `?limit=${data.limit}` : ''}` +
        `${data.offset ? `&offset=${data.offset}` : ''}` +
        `${data.order ? `&order=${data.order}` : '&order=sales_view_quantity'}` +
        `${data.countryId ? `&country_id=${data.countryId}` : ''}` +
        `${data.category_id ? `&category=${data.category_id}` : ''}` +
        `${data.brand_id ? `&brand=${data.brand_id}` : ''}` +
        `${data.feature_availability ? `&feature_availability=${data.feature_availability}` : ''}`,
        'GET', data, "Thelia"
    ),
    getListProducts: (data) => BASE_API.makeRequest(
        `${BASE_API.theliaApi}/api/products_list/` +
        `${data.limit ? `?limit=${data.limit}` : ''}` +
        `${data.offset ? `&offset=${data.offset}` : ''}` +
        `${data.order ? `&order=${data.order}` : '&order=sales_view_quantity'}` +
        `${data.countryId ? `&country_id=${data.countryId}` : ''}` +
        `${data.id ? `&id=${data.id}` : ''}` +
        `${data.category_id ? `&category=${data.category_id}` : ''}` +
        `${data.brand ? `&brand=${data.brand}` : ''}` +
        `${data.feature_availability ? `&feature_availability=${data.feature_availability}` : ''}`,
        'GET', data, "Thelia"
    ),
    getIndividualProduct: (data) => BASE_API.makeRequest(
        `${BASE_API.theliaApi}/api/products/${data.productId}?document=1` + `${data.countryId ? `&country_id=${data.countryId}` : ''}`,
        'GET', null, "Thelia"),
    getIndividualCategories: (data) => BASE_API.makeRequest(`${BASE_API.theliaApi}/api/categories/${data.categoryId}`, 'GET', null, "Thelia"),
    getCategoryChildren: (data) => BASE_API.makeRequest(`${BASE_API.fastApi}/api/category_children?category_id=${data}`, 'GET',),
}

export const API_PRODUCT_DELIVERY_DELAY = {
    getContent: () => BASE_API.makeRequest(`${BASE_API.theliaApi}/api/ConfigApi`, 'GET', null, "Thelia")
};

export const CONTENT_API = {
    getContent: (contentId) => BASE_API.makeRequest(`${BASE_API.fastApi}/api/content/custom_attribute/?content_id=${contentId}&locale=${locale}`)
};

export const BRAND_DETAILS_API = {
    getContent: (brandId) => BASE_API.makeRequest(`${BASE_API.fastApi}/api/brands/${brandId}/${locale}`)
};