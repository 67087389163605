import React, { useState, useEffect } from 'react';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setCountry } from '../../redux/slices/countrySlice.js';


const CustomDropdown = ({ countryDropdownInfo }) => {
  const selectedCountry = useSelector((state) => state.country?.selectedCountry);

  const [isOpen, setIsOpen] = useState(false);
  const { getCountryFlag } = useLocaleConfigSelectors();
  const countryFlag = getCountryFlag;
  const { t } = useTranslation();

  const dispatch = useDispatch();

   const handleSelect = (country) => {
        if (country) {
            dispatch(setCountry(country));
            localStorage.setItem("country_tax", country.tax_title);
            localStorage.setItem("country_id", country.id);
            setIsOpen(false);
        } else {
            console.error('Invalid country or missing tax_title');
        }
    };

  // Render different UI based on 'excludeVAT' and 'countryDropdownInfo.length'

  return localStorage.getItem('excludeVAT') === 'true' ? (
    <div className='dropdown-countries' onClick={() => setIsOpen(false)}>
      {selectedCountry ? (
        <>
          {t('Delivery Country')}
          <img src={countryFlag[selectedCountry.id]} alt='' className='dropdown-flag selected-flag' />
          <p style={{ color: 'white' }}>0% {t('VAT')}</p>
        </>
      ) : (
        <>
          <i className='fa fa-caret-down'></i> {t('Delivery Country')}
        </>
      )}
    </div>
  ) : (
    countryDropdownInfo.length > 1 ? (
      <div className='dropdown'>
        <div className='dropdown-countries' onClick={() => setIsOpen(!isOpen)}>
          {selectedCountry ? (
            <>
              <i className='fa fa-caret-down'></i>
              {t('Delivery Country')}
              <img src={countryFlag[selectedCountry.id]} alt='' className='dropdown-flag selected-flag' />
            </>
          ) : (
            <>
              <i className='fa fa-caret-down'></i> {t('Delivery Country')}
            </>
          )}
        </div>
        {isOpen && (
          <div className='dropdown-list'>
            {countryDropdownInfo.map((item, index) => (
              <div key={index}
                   className={`dropdown-item ${selectedCountry && selectedCountry.id === item.id ? 'selected-country' : ''}`}
                   onClick={() => handleSelect(item)}>
                <img src={countryFlag[item.id]} alt='' className='dropdown-flag' />
                {item.title} {item.tax_title}
              </div>
            ))}
          </div>
        )}
      </div>
    ) : (
      // Handling when there is only one item in 'countryDropdownInfo'
      <>
        {countryDropdownInfo.map((item, index) => (
          <div className='dropdown-item-single' key={index}>
            {t('Delivery Country')}
            <img src={countryFlag[item.id]} alt='' className='dropdown-flag selected-flag' />
          </div>
        ))}
      </>
    )
  );
};


export default CustomDropdown;