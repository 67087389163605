import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import config from '../../config/config.js';
import { getTheliaToken } from '../../helpers/session.js';
import { getLangCode } from '../../helpers/localStorageConfig.js';
import { getCustomerId } from '../api/cart.js';

export const ioonsApi = createApi({
    reducerPath: 'IoonsApi',
    baseQuery: fetchBaseQuery(
        {
            baseUrl: `${config.apiUrl}/api/`, prepareHeaders: (headers) => {
                headers.set('Access-Control-Allow-Origin', '*');
                headers.set('Content-Type', 'application/json');
                headers.set('Accept', 'application/json');
                headers.set('Authorization', getTheliaToken());
                return headers;
            },
        }),
    // keepUnusedDataFor: 1800,
    endpoints: (builder) => ({
        getConfig: builder.query({
            query: () => 'ConfigApi',
            // keepUnusedDataFor: 1800,
            keepUnusedDataFor: 0,
        }),
        getRewritingUrl: builder.query({
            query: ({ view_locale, view_url }) => ({
                url: `rewriting`,
                params: { view_locale, view_url },
            }),
        }),
        getIndividualCategories: builder.query({
            query: (data) => ({ url: `categories/${data}` }),
            transformResponse: (baseQueryReturnValue) => baseQueryReturnValue.categoryItems[0],
        }),
        getIndividualProduct: builder.query({
            query: (data) => ({ url: `products/${data.productId}?document=1` + `${data.countryId ? `&country_id=${data.countryId}` : ''}` }),
            transformResponse: (baseQueryReturnValue) => baseQueryReturnValue[0],
        }),
        // This endpoint from below is not used anymore - deprecated
        // getIndividualPSE: builder.query({
        //     query: ({ PRODUCT_SALE_ELEMENT }) => ({ url: `pse/${PRODUCT_SALE_ELEMENT}` }),
        //     transformResponse: (baseQueryReturnValue) => baseQueryReturnValue[0],
        // }),
        getPSE: builder.query({
            query: ({ PRODUCT_SALE_ELEMENT, countryId }) => ({
                url: `get_pse/${PRODUCT_SALE_ELEMENT}` + (countryId ? `?country_id=${countryId}` : '')
            }),
            transformResponse: (baseQueryReturnValue) => baseQueryReturnValue[0],
        }),
        getExternalOrder: builder.query({
            query: (productID) => ({ url: `ExternalOrderApi/features/product/${productID}/${getLangCode}` }),
        }),
        getExternalApiOrder: builder.query({
            query: (order_id) => ({ url: `ExternalOrderApi/order/${order_id}?customer=${getCustomerId()}` }),
            transformResponse: (response) => response.OrderItems[0],
        }),
        getCoupon: builder.mutation({
            query: ({ coupon_code, cart_id, customer_id }) => ({
                url: 'ExternalOrderApi/insertCoupon/',
                method: 'POST',
                body: { coupon_code, cart_id, customer_id },
            }),
        }),
        updatePassword: builder.mutation({
            query: (data) => ({
                url: `changePassword`,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});
export const {
    usePrefetch,
    useGetConfigQuery,
    useGetIndividualCategoriesQuery,
    useGetIndividualProductQuery,
    useGetExternalOrderQuery,
    useGetExternalApiOrderQuery,
    useGetCouponMutation,
    useUpdatePasswordMutation,
    // useGetIndividualPSEQuery,
    useGetPSEQuery,
} = ioonsApi;
