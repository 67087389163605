import config from "react-global-configuration";
import axios from "axios";
import PropTypes from "prop-types";
import {fetchToken, getTheliaToken, getUserIdentifier} from "./session.js";
import { setAddressId, setCustomerId } from '../redux/api/cart.js';
import { t } from 'i18next';

const userType = PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
    address: PropTypes.arrayOf(PropTypes.shape({
        address1: PropTypes.string,
        address2: PropTypes.string,
        complete_address: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
        state: PropTypes.string,
        cellphone: PropTypes.string,
        zipcode: PropTypes.string,
    }))
});


export const getUser = async () => {
    const token = fetchToken();
    if (!token) return null;

    try {
        const url = config.get('apiUrl');

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`
            }
        }
        const user_identifier = getUserIdentifier();
        const {data} = await axios.get(`${url}/api/ExternalOrderApi/customer/?email=${user_identifier}`, options);
        if (data?.CustomerItems[0]) {
            const mappedUser = {
                email: data.CustomerItems[0].EMAIL,
                id: data.CustomerItems[0].ID,
                title_id: data.CustomerItems[0].TITLE,
                ref: data.CustomerItems[0].REF,
                firstName: data.CustomerItems[0].FIRSTNAME,
                lastName: data.CustomerItems[0].LASTNAME,
                title: data.CustomerItems[0].TITLE === 1 ? t('Mr') : data.CustomerItems[0].TITLE === 2 ? t('Miss') : t('Company'),
                company: data.CustomerItems[0].COMPANY,
                address: [{
                    address_first_name: data.CustomerItems[0].ADDRESS_FIRSTNAME,
                    address_last_name: data.CustomerItems[0].ADDRESS_LASTNAME,
                    complete_address: data.CustomerItems[0].ADDRESS1 + " " + data.CustomerItems[0].ADDRESS2 + " " + data.CustomerItems[0].ADDRESS3,
                    address1: data.CustomerItems[0].ADDRESS1,
                    address2: data.CustomerItems[0].ADDRESS2,
                    city: data.CustomerItems[0].CITY,
                    country: data.CustomerItems[0].COUNTRY_TITLE,
                    state: data.CustomerItems[0].STATE_TITLE,
                    cellphone: data.CustomerItems[0].CELLPHONE,
                    zipcode: data.CustomerItems[0].ZIPCODE,
                    address_id: data.CustomerItems[0].ADDRESS_ID,
                    state_id: data.CustomerItems[0].STATE_ID,
                    state_title: data.CustomerItems[0].STATE_TITLE,
                    country_id: data.CustomerItems[0].COUNTRY_ID,
                    phone: data.CustomerItems[0].PHONE,
                    title_id: data.CustomerItems[0].ADDRESS_TITLE_ID,
                }]
            };
            setAddressId(data.CustomerItems[0].ADDRESS_ID);
            setCustomerId(data.CustomerItems[0].ID);

            return mappedUser;
        }
    } catch (error) {
        console.log(error)
    }
}

export const API = {
    GET : 'GET',
    POST : 'POST',
    PUT : 'PUT',
    DELETE : 'DELETE',
    PATCH : 'PATCH',
    HEAD : 'HEAD',
    OPTIONS : 'OPTIONS',
    TRACE : 'TRACE',
    CONNECT : 'CONNECT',
    endpoint: config.get('apiUrl'),
    pathToExternalOrderApi: `/api/ExternalOrderApi/`,
    login: (data) => API.makePostRequest(`${API.endpoint}/api/login`, data),
    register: (user, token) => API.makePostRequest(`${API.endpoint}${API.pathToExternalOrderApi}customer`, user, token),
    insertAddress: (user, token) => API.makePostRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}address`, user, token),
    checkEmail: (data, token) => API.makeGetRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}customer?email=${data.confirmEmail}&with_address=0`, data, token),
    checkCustomerById: (data, token) => API.makeGetRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}customer/${data.id}?with_address=0`, data, token),
    convertToFullCustomer: (data) => API.makePostRequest(`${API.endpoint}${API.pathToExternalOrderApi}convertToFullCustomer`, data),
    changeCurrentCustomer: (data) => API.makePutRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}customer/${data.id}`, data),
    changeCurrentCustomerAddress: (data) => API.makePutRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}address/${data.ADDRESS_ID}`, data),
    addNewCustomerAddress: (data) => API.makePostRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}address`, data),
    checkAddress: (data) => API.makeGetRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}address/${data.id}&limit=100`, data),
    getOrdersFromCustomer: (data) => API.makeGetRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}order/?customer=${data.id}&limit=999`, data),
    checkSubscriber: (data) => API.makeGetRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}newsletter?email=${data}`, data),
    addSubscriber: (data) => API.makePostRequest(`${API.endpoint}${API.pathToExternalOrderApi}newsletter`, data),
    changeSubscriber: (data) => API.makePutRequestToken(`${API.endpoint}${API.pathToExternalOrderApi}newsletter/${data.id}`, data),
    downloadPDF: (data) => API.makeGetRequestForPDF(`${API.endpoint}/index_dev.php/account/api/order/pdf/invoice/${data.id}/${data.locale}`, data),
    downloadPDFCredit: (data) => API.makeGetRequestForPDF(`${API.endpoint}/index_dev.php/account/api/order/pdf/credit/${data.id}/${data.locale}`, data),
    getConfigApi: () => API.makeGetRequestToken(`${API.endpoint}/api/ConfigApi`),
    getCounty: (country_id) => API.makeGetRequestToken(`${API.endpoint}/api/ExternalOrderApi/county/${country_id}`),
    getCompany: (company_id) => API.makeGetRequestToken(`${API.endpoint}/api/customer/getCompany/${company_id}`),
    saveCompany: (data) => API.makePostRequestToken(`${API.endpoint}/api/customer/company`, data),
    verifyVAT: (vat) => API.makeGetRequestToken(`${API.endpoint}/api/verifyuid/${vat}`),
    makePostRequest: (url, data) => {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `${getTheliaToken()}`
            },
            body: JSON.stringify(data)
        }).then(response => response.json());
    },
    makePostRequestToken: (url, data) => {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `${getTheliaToken()}`
            },
            body: JSON.stringify(data)
        }).then(response => response.json());
    },
    makeGetRequestToken: (url) => {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `${getTheliaToken()}`,
                "Accept": "application/json",
            },
        }).then(response => response.json());
    },
    makePutRequestToken: (url, data) => {
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `${getTheliaToken()}`
            },
            body: JSON.stringify(data)
        }).then(response => response.json());
    },
    makeGetRequestForPDF: (url, data) => {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': `${getTheliaToken()}`
            },
        })
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${data.REF}.pdf`;
                link.click();
                URL.revokeObjectURL(url);
            });
    },
    makeDeleteRequestToken: (url) => {
        return fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `${getTheliaToken()}`
            },
        }).then(response => response.json());
    }
}