import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import config from "react-global-configuration";
import { getTheliaToken } from "../../../helpers/session.js";
import { CONTENT_API, CATEGORIES_API } from "../../../helpers/product";
import { useGetConfigQuery } from '../../../redux/service/ioonsService.js';
import useLocaleConfigSelectors from '../../../helpers/useLocaleConfigSelectors.js';
import { useGetCategoriesHierarchyQuery, useGetCategoriesQuery } from '../../../redux/service/fastApiService.js';

const MobileNavMenu = ({ strings }) => {
 
	const { data: externalApiCategoriesCount, isLoading: loadingCategoriesCount, isSuccess: isSuccessCategoriesCount} = useGetCategoriesQuery({}, {});
	const { data: externalApiCategories, isLoading: loadingCategories, isSuccess: isSuccessCategories} = useGetCategoriesHierarchyQuery({}, {});
  const { data: configApi, isSuccess } = useGetConfigQuery();

  const [contentArray, setContentArray] = useState([]);
  const [hardCodedLinks, setHardcodedLinks] = useState([]);
  const [categoryIconObject, setCategoryIconObject] = useState({});
  const sideMenuExpand = e => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  useEffect(() => {

/*
    API_PRODUCT_DELIVERY_DELAY.getContent().then((data) => {
      if (data.left_menu_content_ids) {
        data.left_menu_content_ids.split(',').forEach(number => {
          CONTENT_API.getContent(number).then((response) => {
            if (response.id == number) {
              const newObject = { title: response.title, id: response.id, url: response?.rewriting_url?.url };
              setContentArray(prevArray => [...prevArray, newObject]);
            }
          });
        });
      }
      if (data.category_icon)
        setCategoryIconObject(data.category_icon);
      if (data.links_left_menu)
        setHardcodedLinks(data.links_left_menu);
    })
*/
  }, []);


  useEffect(() => {
    if (isSuccess) {
      if (configApi.left_menu_content_ids) {
        configApi.left_menu_content_ids.split(',').forEach(number => {
          CONTENT_API.getContent(number).then((response) => {
            if (response.id == number) {
              const newObject = { title: response.title, id: response.id, url: response?.rewriting_url?.url };
              setContentArray(prevArray => [...prevArray, newObject]);
            }
          });
        });
      }
      if (configApi.category_icon)
        setCategoryIconObject(configApi.category_icon);
      if (configApi.links_left_menu)
        setHardcodedLinks(configApi.links_left_menu);
    }

  }, [configApi]);

  useEffect(() => {
    const offCanvasNav = document.querySelector("#offcanvas-navigation");
      if (!offCanvasNav) {
          return;
      }
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");
    const anchorLinks = offCanvasNav.querySelectorAll("a");

            for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
                offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
            }

            const menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
            const numMenuExpand = menuExpand.length;

            for (let i = 0; i < numMenuExpand; i++) {
                menuExpand[i].addEventListener("click", (e) => sideMenuExpand(e));
            }

            for (let i = 0; i < anchorLinks.length; i++) {
                anchorLinks[i].addEventListener("click", () => closeMobileMenu());
            }
        }
    );
	
	if(loadingCategories || loadingCategoriesCount) return null;
	if(!externalApiCategories || !externalApiCategoriesCount) return null;
	if(!isSuccessCategories || !isSuccessCategoriesCount) return null;
	
	
	return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        {externalApiCategories.map((item, index) => (
          externalApiCategoriesCount[item.Id]?.count > 0 &&
          <li key={index} className="menu-item-has-children">
            <Link to={{
              pathname: `${process.env.PUBLIC_URL}/${item.URL}`,
              state: [`${item.Title}`, `${item.Id}`]
            }}>
              <span className="row">
                <span className="category-icon col-2">
                  {categoryIconObject[item.Id] ? (
                    <i className={categoryIconObject[item.Id]}></i>
                  ) : (
                    <i className="icon-052-oval"></i>
                  )}
                </span>
                <span className={`category-title ${item.children ? 'col-9' : 'col-10'}`}>{item.Title}</span>
              </span>
            </Link>
            {/* <span class='menu-expand'><i></i></span> */}
            {item.children && item.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) &&
              <ul className="sub-menu">
                {item.children.map((child, index) => (
                  externalApiCategoriesCount[child.Id]?.count > 0 &&
                  <li key={index} className={`${item.children && item.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? 'menu-item-has-children' : ''}`}>
                    <Link to={{
                      pathname: `${process.env.PUBLIC_URL}/${child.URL}`,
                      state: [`${child.Title}`, `${child.Id}`]
                    }}>
                      <span className="row">
                        <span className="category-icon col-2">
                          {categoryIconObject[child.Id] ? (
                            <i className={categoryIconObject[child.Id]}></i>
                          ) : (
                            <i className="icon-052-oval"></i>
                          )}
                        </span>
                        <span className={`category-title ${child.children && child.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? 'col-9' : 'col-10'}`}>{child.Title}</span>
                      </span>
                    </Link>
                    {child.children && child.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) &&
                      <ul className="sub-menu">
                        {child.children.map((nephew, index) => (
                          externalApiCategoriesCount[nephew.Id]?.count > 0 &&
                          <li key={index} className={`${child.children && child.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? 'menu-item-has-children' : ''}`}>
                            <Link to={{
                              pathname: `${process.env.PUBLIC_URL}/${nephew.URL}`,
                              state: [`${nephew.Title}`, `${nephew.Id}`]
                            }}>
                              <span className="row">
                                <span className="category-icon col-2">
                                  {categoryIconObject[nephew.Id] ? (
                                    <i className={categoryIconObject[nephew.Id]}></i>
                                  ) : (
                                    <i className="icon-052-oval"></i>
                                  )}
                                </span>
                                <span className={`category-title ${nephew.children && nephew.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? 'col-9' : 'col-10'}`}>{nephew.Title}</span>
                              </span>
                            </Link>
                            {nephew.children && nephew.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) &&
                              <ul className="sub-menu">
                                {nephew.children.map((grandson, index) => (
                                  externalApiCategoriesCount[grandson.Id]?.count > 0 &&
                                  <li key={index} className={`${nephew.children && nephew.children.some(child => externalApiCategoriesCount[child.Id]?.count > 0) ? 'menu-item-has-children' : ''}`}>
                                    <Link to={{
                                      pathname: `${process.env.PUBLIC_URL}/${grandson.URL}`,
                                      state: [`${grandson.Title}`, `${grandson.Id}`]
                                    }}>
                                      <span className="row">
                                        <span className="category-icon col-2">
                                          {categoryIconObject[grandson.Id] ? (
                                            <i className={categoryIconObject[grandson.Id]}></i>
                                          ) : (
                                            <i className="icon-052-oval"></i>
                                          )}
                                        </span>
                                        <span className="category-title col-10">{grandson.Title}</span>
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            }
                          </li>
                        ))}
                      </ul>
                    }
                  </li>
                ))}
              </ul>
            }
          </li>
        ))}
        {contentArray.map((item, index) => (
          <li key={index}>
            <Link to={{
              pathname: `${process.env.PUBLIC_URL}/${item?.url}`,
              state: [`${item.title}`, `${item.id}`]
            }}>
              <span className="row">
                <span className="category-icon col-2">
                  {categoryIconObject[item.Id] ? (
                    <i className={categoryIconObject[item.Id]}></i>
                  ) : (
                    <i className="icon-052-oval"></i>
                  )}
                </span>
                <span className="category-title col-10">{item.title}</span>
              </span>
            </Link>
          </li>
        ))}
        {hardCodedLinks.map((link, index) => (
          <li key={index}>
            <Link to={{
              pathname: `${process.env.PUBLIC_URL}/${link?.href}`,
              state: [`${link.title}`]
            }}>
              <span className="row">
                <span className="category-icon col-2">
                  {categoryIconObject[link.Id] ? (
                    <i className={categoryIconObject[link.Id]}></i>
                  ) : (
                    <i className="icon-052-oval"></i>
                  )}
                </span>
                <span className="category-title col-10">{link.title}</span>
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object
};

export default multilanguage(MobileNavMenu);
