import config from "react-global-configuration";
import { clearCart, DELETE_ALL_FROM_CART, deleteAllFromCart } from '../redux/actions/cartActions.js';
//import * as Sentry from '@sentry/react';

export const token_name = 'jwtToken';
export const APP_VERSION = "1.0.1.2";

export const setToken = (token) => {
    var currentTime = new Date();
    var thirtyMinutesFromNow = new Date(currentTime.getTime() + 360 * 60000);

    // console.log("SESSION SET TOKEN: setting token at date:", new Date());

    localStorage.setItem(token_name, JSON.stringify({ "token": token, "expiresAt": thirtyMinutesFromNow.toString() }))// make up your own token
    window.dispatchEvent(new Event('storage')); // Trigger storage event manually

    if (getUserExpress()) {
        localStorage.removeItem("user_express");
        if(localStorage.getItem("user_express_data")) {
            localStorage.removeItem('user_express_data');
        }
    }
}
export const setUserIdentifier = (userIdentifier) => {
    localStorage.setItem("user_identifier", userIdentifier)
}
export const setExpress = (customerId) => {
    localStorage.setItem("user_express", customerId)
}
export const setExpressData = (data) => {
    var currentTime = new Date();
    var thirtyMinutesFromNow = new Date(currentTime.getTime() + 30 * 60000);
    localStorage.setItem("user_express_data", JSON.stringify({ "data": data, "expiresAt": thirtyMinutesFromNow.toString() }))
}

//Outdated method => don't use it in the future.
export const getCountrySelected = () => {
    const countrySelected = localStorage.getItem("country_id");
    if (countrySelected) {
        return countrySelected;
    } else {
        return null;
    }
};

//Outdated method => don't use it in the future.
export const getCountryTax = () => {
    const countryTax = localStorage.getItem("country_tax");
    if (countryTax) {
        return countryTax;
    } else {
        return null;
    }
};

export const getUserIdentifier = () => localStorage.getItem("user_identifier")

export const fetchToken = () => {

   // Sentry.captureMessage('in fetch token');
    checkExpressData();
    const token_cookie = JSON.parse(localStorage.getItem(token_name));
  //  Sentry.captureMessage('token_name ' + token_name);
    if (token_cookie) {
        const storedDate = new Date(token_cookie.expiresAt);
      //  Sentry.captureMessage('storedate ' + storedDate);
        const currentTime = new Date();
     //   Sentry.captureMessage('currentTime ' + currentTime);
        if (storedDate.getTime() < currentTime.getTime()) {
            removeToken();
            return null;
        }

        return token_cookie.token
    }
    return null
}

export const getUserExpress = () => {
    const userExpress = localStorage.getItem("user_express");
    if (userExpress) {
        return userExpress;
    } else {
        return null;
    }
};

export const getExpressData = () => {
    const expressData = JSON.parse(localStorage.getItem("user_express_data"));
    if (expressData) {
        removeExpress();
        return expressData.data
    }
    return null
}

export const checkExpressData = () => {
    const expressData = JSON.parse(localStorage.getItem("user_express_data"));
    if (expressData) {
        const storedDateE = new Date(expressData.expiresAt);
        const currentTimeE = new Date();
        if (storedDateE.getTime() < currentTimeE.getTime()) {
            localStorage.removeItem('user_express_data');
        }
    }
}

export const removeExpress = () => {
    localStorage.removeItem('user_identifier');
    localStorage.removeItem('user_express');
    if(localStorage.getItem("user_express_data")) {
        localStorage.removeItem('user_express_data');
    }
};

export const getTheliaToken = () => {
    return "TOKEN " + config.get('token');
}

export const getBearerToken = () => {
    return "Bearer " + fetchToken();
}

export const getIsAdmin = () => {
    const admin = localStorage.getItem('isAdmin');
    if (admin) {
        return admin === 'true';
    } else {
        return false;
    }
};

export const removeToken = () => {
    // console.log("REMOVE TOKEN !!!!")
    localStorage.removeItem(token_name)
    localStorage.removeItem('user_identifier')
    localStorage.removeItem('customer_id')
    localStorage.removeItem('address_id')
    localStorage.removeItem('appliedCoupon')
    localStorage.removeItem('isAdmin')
    localStorage.setItem('isCompany', false)
    localStorage.setItem('excludeVAT', false)
    localStorage.setItem('profileName', '')

    //console.log('removeTokenSession');
}
