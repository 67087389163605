import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from '../../redux/actions/cartActions';
import { fetchToken, token_name } from '../../helpers/session.js';
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { setUser } from '@sentry/react';
import { useGetSystemAllQuery } from '../../redux/service/fastApiService.js';
import { getUser } from '../../helpers/auth.js';
import useWindowSize from '../../helpers/useWindowSize.js';
import { size } from 'lodash/collection.js';

const IconGroup = ({
    currency,
    cartData,
    wishlistData,
    compareData,
    deleteFromCart,
    iconWhiteClass,
    refOfIcon
}) => {
    const { t } = useTranslation();
    const [width] = useWindowSize();
    const [accountDropDown, setAccountDropDown] = useState(false);
    const [cartDropDown, setCartDropDown] = useState(false);
    const [shopCartValue, setShopCartValue] = useState(false);
    const triggerMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );
        offcanvasMobileMenu.classList.add("active");
    };
    const signOut = () => {
        localStorage.removeItem(token_name);
        localStorage.removeItem('user_identifier');
        localStorage.removeItem('customer_id');
        localStorage.removeItem('address_id');
        localStorage.removeItem('appliedCoupon');
        localStorage.removeItem('isAdmin');
        localStorage.setItem('isCompany', false);
        localStorage.setItem('excludeVAT', false);
        localStorage.setItem('profileName', '');
    }

    const { data: { storePhone, storeSupportTime } } = useGetSystemAllQuery({}, {
        refetchOnMountOrArgChange: true,
        selectFromResult: (result) => ({
            ...result,
            data: {
                storePhone: result.data ? result.data['store_phone'] : "",
                storeSupportTime: result.data ? result.data['support_time_mo_th'] : "",
            }
        }),
    });
    let path = useLocation().pathname;

    const [profileName, setProfileName] = useState(localStorage.getItem('profileName') || '');

    useEffect(() => {
        if (fetchToken() && (localStorage.getItem('profileName') === null || localStorage.getItem('profileName') === ""))
            getUserLocal();
        window.addEventListener('updateProfileName', (event) => {
            setProfileName(event.detail.newProfileName || '');
        });
        return () => {
            window.removeEventListener('updateProfileName', () => {});
        };
    }, []);

    async function getUserLocal() {
        const userinfo = await getUser();
        if (userinfo) {
            let newName;
            if (userinfo.title_id === 3) {
                newName = userinfo.company;
            } else {
                newName = `${userinfo.firstName} ${userinfo.lastName}`;
            }
            localStorage.setItem('profileName', newName);
            // Update the component's state with the new profileName
            setProfileName(newName);
        }
    }

    useEffect(() => {
        if (path !== "/login-register" && path !== "/forgot-password") {
            localStorage.setItem("lastLocation", path);
        }
    }, [path]);

    let itemsInCart = 0;
    const refZero = useRef(null)

    const [clicked, setClicked] = useState(false);

    const refOne = useRef(null)
    const refOne2 = useRef(null)

    const handleAccountDropdownClick = () => {
        setAccountDropDown(!accountDropDown)
    };

    const handleCartDropdownClick = () => {
        setCartDropDown(!cartDropDown)
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if ((refOne?.current?.contains(e.target)) || refOne2?.current?.contains(e.target)) {
                setClicked(true);
            }
            else {
                setClicked(false);
                setAccountDropDown(false);
            }
        }

        document.addEventListener("click", handleClickOutside);

        return () => {
            // Clean up the event listener when the component is unmounted
            document.removeEventListener("click", handleClickOutside);
        };
    }, [clicked])

    const [isPhoneHoursExpanded, setIsPhoneHoursExpanded] = useState(false);
    const phoneHoursWrapperRef = useRef(null);
    const togglePhoneHours = () => {
        setIsPhoneHoursExpanded(!isPhoneHoursExpanded);
    };
    const handleClickOutside = (event) => {
        if (phoneHoursWrapperRef.current && !phoneHoursWrapperRef.current.contains(event.target)) {
            // Clicked outside the phone-hours-wrapper, toggle the state
            setIsPhoneHoursExpanded(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const formatNumber = (num) => {
        const kPart = Math.floor(num / 1000);
        return kPart === 0 ? num : `${kPart}k`;
    };


    return (
        <div className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}>
            <div className="profile-wrapper">
                <div className="first-three-icons">
                    <div className="same-style cart-wrap account-setting real-margin" style={{ display: "flex", width: '116px' }}>
                        <button>
                            <a href={width > 767 ? undefined : 'tel:' + storePhone}>
                                <div
                                    className={`phone-hours-wrapper ${isPhoneHoursExpanded ? 'phone-hours-wrapper-hover-bg' : ''}`}
                                    ref={phoneHoursWrapperRef}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: (width > 767 && isPhoneHoursExpanded) ? '189.5px' : '116px',
                                        transition: 'width 0.3s ease'
                                    }}
                                    onClick={togglePhoneHours}
                                >
                                    {width > 767 && (
                                        <span className={`count-style ${isPhoneHoursExpanded ? 'count-style-animation-appear' : 'count-style-animation-disappear'}`}>
                                            <i className="pe-7s-call" />
                                        </span>
                                    )}
                                    <span className="big-phone"><i className="pe-7s-call" style={{ display: (width > 767 && isPhoneHoursExpanded) ? 'none' : 'block' }} /></span>
                                    <span className="phone-hours-header" style={{ display: (width > 767 && isPhoneHoursExpanded) ? 'none' : 'block' }}>
                                        {storeSupportTime}
                                        {width > 767 ? <br /> : null}
                                        {width > 767 ? storePhone?.replace(/\s/g, '') : null}
                                    </span>
                                    {(width > 767 && isPhoneHoursExpanded) &&
                                        <span className="phone-hours-header-big">
                                            {storePhone}
                                        </span>
                                    }
                                </div>
                            </a>
                        </button>
                    </div>
                    {/*<div className="same-style cart-wrap account-setting">
                        <button>
                        <i className="pe-7s-info" />
                        </button>
                    </div>*/}
                    <div className="same-style cart-wrap account-setting real-margin" style={{ display: "flex" }}>
                        <button
                            className="account-setting-active"
                            //onClick={e => handleClick(e)}
                            onClick={() => {
                                handleAccountDropdownClick();
                                setShopCartValue(false);
                            }}
                            ref={refOne}
                        >
                            <i className={`pe-7s-user-female ${accountDropDown ? 'theme-color' : ''}`} />
                        </button>

                        <div className={(!clicked || !accountDropDown) ? "account-dropdown" : "account-dropdown active"} ref={refOne2}>
                            <span className="arrowbottom"></span>
                            <ul>
                                {/*//TODO Here you have to pass the user from myAccount as well, but at the moment it works.*/}
                                {fetchToken() ?
                                    (<>
                                        <li>
                                            <Link to={process.env.PUBLIC_URL + '/account'}>
                                                <span className="theme-color-hover"><i className="fa fa-user"></i>{'\u00A0'}{" "}{t('Account')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={process.env.PUBLIC_URL + '/login-register'} onClick={signOut}>
                                                <span className="theme-color-red-hover"><i className="fa fa-power-off"></i>{'\u00A0'}{" "}{t('Log out')}</span>
                                            </Link>
                                        </li>
                                    </>)
                                    :
                                    (<li>
                                        <Link to={process.env.PUBLIC_URL + "/login-register"}>
                                            <span className="theme-color-hover"><i className="fa fa-sign-in"></i>{'\u00A0'}{" "}{t('Login')}/{t('Register')}</span>
                                        </Link>
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>

                    {/*<div className="same-style header-compare">
                        <Link to={process.env.PUBLIC_URL + "/compare"}>
                        <i className="pe-7s-shuffle" />
                        <span className="count-style">
                            {compareData && compareData.length ? compareData.length : 0}
                        </span>
                        </Link>
                        </div>*/}
                    {/*<div className="same-style header-wishlist">
                        <Link to={`${process.env.PUBLIC_URL  }/wishlist`}>
                        <i className="pe-7s-like" />
                        <span className="count-style">
                            {wishlistData && wishlistData.length ? wishlistData.length : 0}
                        </span>
                        </Link>
                    </div>*/}
                    <div className="same-style cart-wrap d-none d-lg-flex">
                        <button className="icon-cart"
                            //onClick={e => handleClick(e)}
                            onClick={() => {
                                handleCartDropdownClick();
                                setShopCartValue(!shopCartValue);
                            }}
                            ref={refZero}
                        >
                            <i className={`pe-7s-shopbag ${cartDropDown ? '' : ''}`} />
                            {/* {cartData && cartData.length ? cartData.length : 0} */}
                            {cartData.map((key) => {
                                itemsInCart = itemsInCart + key.quantity
                                return (
                                    <span
                                        key={key.PRODUCT_SALE_ELEMENT}
                                        className={`count-style ${itemsInCart > 99 && 'count-style-smaller-font'}`}
                                    >
                                        {formatNumber(itemsInCart)}
                                    </span>
                                );
                            })}
                        </button>
                        {/* menu cart */}
                        <MenuCart
                            cartData={cartData}
                            currency={currency}
                            deleteFromCart={deleteFromCart}
                            shopCartValue={shopCartValue}
                            refOfIcon={refZero}
                        />
                    </div>
                    <div className="same-style cart-wrap d-flex d-lg-none real-margin" style={{ alignItems: "center" }}>
                        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
                            <i className="pe-7s-shopbag" />
                            <span className={`count-style ${itemsInCart > 99 && 'count-style-smaller-font'}`}>
                                {/* {cartData && cartData.length ? cartData.length : 0} */}
                                {formatNumber(itemsInCart)}
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="profile_name">{fetchToken() && localStorage.getItem('profileName') ? localStorage.getItem('profileName').toLowerCase() : ''}</div>
            </div >
            <div className="same-style cart-wrap mobile-off-canvas d-block d-xl-none top-mob-menu">
                <button
                    className="mobile-aside-button"
                    onClick={() => triggerMobileMenu()}
                >
                    <i className="pe-7s-menu" />
                </button>
            </div>
        </div >
    );
};

IconGroup.propTypes = {
    cartData: PropTypes.array,
    compareData: PropTypes.array,
    currency: PropTypes.object,
    iconWhiteClass: PropTypes.string,
    deleteFromCart: PropTypes.func,
    wishlistData: PropTypes.array
};

const mapStateToProps = state => {
    return {
        currency: state.currencyData,
        cartData: state.cartData,
        wishlistData: state.wishlistData,
        compareData: state.compareData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deleteFromCart: (item, addToast, translatedMessage) => {
            dispatch(deleteFromCart(item, addToast, translatedMessage));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
