import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import FullMenu from "../../components/header/FullMenu";
import CountryDropdown from "../../components/header/CountryDropdown.jsx";
import AdminHeader from "../../components/header/AdminHeader";
import Appx from "../../components/react-elastic-thelia/elastic";
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module/dist/TagManager.js';
import useWindowSize from '../../helpers/useWindowSize.js';
import { useTranslation } from "react-i18next";
import config from 'react-global-configuration';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';
import { useGetConfigQuery } from '../../redux/service/ioonsService.js';
import { useGetTagManagerQuery } from '../../redux/service/fastApiService.js';
import { getIsAdmin } from '../../helpers/session.js';
import CountryDisplay from '../../components/header/CountryDisplay';
import SalesBarComponent from './SalesBar';
import { text } from '@fortawesome/fontawesome-svg-core';


const tagManagerArgs = {
    gtmId: config.get('gtmId')
}

const HeaderOne = ({
    layout,
    top,
    borderStyle,
    headerPaddingClass,
    headerPositionClass,
    headerBgClass
}) => {
    const { t } = useTranslation();
    const [width] = useWindowSize();
    const { getLogoHeader, getCountryVisible: countryDropdownInfo, getStoreName, getDisplayCountryHeader } = useLocaleConfigSelectors();
 
    const { data: tagManager, isSuccess } = useGetTagManagerQuery({});
    useEffect(() => {
        if (isSuccess) {
            const trackingCode = tagManager?.googletagmanager_trackingcode;
            if (trackingCode) {
                try {
                    eval(trackingCode);
                } catch (error) {
                    console.error("Failed to execute tracking code:", error);
                }
            }
        }
    }, [isSuccess, tagManager]);

    const [headerTop, setHeaderTop] = useState(0);
    // useEffect(() => {
    //     const header = document.querySelector(".sticky-bar");
    //     setHeaderTop(header.offsetTop);
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    // const [scroll, setScroll] = useState(0);
    // const handleScroll = () => {
    //     setScroll(window.scrollY);
    // };

    const { data: configApi, isSuccess: isSuccessConfigApi } = useGetConfigQuery();
    const [displayAdvent, setDisplayAdvent] = useState();

    useEffect(() => {
        if (isSuccessConfigApi) {
            if (configApi.displayAdvent) {
                setDisplayAdvent(configApi.displayAdvent);
            }
        }
    }, [configApi]);

    const isAdmin = getIsAdmin();
 //   const isAdmin = true;
    const backOfficePath = config.get('apiUrl') + '/admin';

    return (
        <header
            className={`header-area clearfix ${headerBgClass ? headerBgClass : ""} ${headerPositionClass ? headerPositionClass : ""}`} >

            <div
                className={` ${headerPaddingClass ? "headerPaddingClass" : ""  } sticky-bar clearfix stick `}
            >
                <SalesBarComponent/>

                {isAdmin &&
                    <div className="admin-header">
                       <AdminHeader storeName={getStoreName} backOfficePath={backOfficePath}/>
                    </div>
                }

                {getDisplayCountryHeader &&
                    <div className="country-header" style={{ maxHeight: '200px' }}>
                        <div className="container">
                            <CountryDropdown countryDropdownInfo={countryDropdownInfo} />
                            {/* <CountryDisplay/> */}
                        </div>
                    </div>
                }

                <div className="banner-top-color" style={{ maxHeight: '200px' }}>
                    <div className={'container'}>
                        <div className={width > 992 ? 'row' : 'row pt-5'}>
                            <div className='col-xl-2 col-lg-2 col-md-4 col-4'
                                 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* header logo */}
                                <Logo imageUrl={getLogoHeader} logoClass='logo' />
                            </div>
                            <div className='d-lg-none col-xl-8 col-lg-8 d-none d-xl-block'>
                                {/* Search Bar */}
                                <Appx location='dropdown' />
                            </div>
                            <div className='col-xl-2 col-lg-10 col-md-8 col-8 center-icons-inside' style={{
                                display: 'flex',
                                alignItems: '',
                                justifyContent: 'flex-end',
                                paddingLeft: 0,
                            }}>
                                {/* Icon group */}
                                <IconGroup />
                            </div>
                            <div className={`d-xl-none col-md-12 col-sm-12 col-12 ${width < 992 ? 'pt-5' : ''}`}>
                                {/* Search Bar */}
                                <Appx location='dropdown' />
                            </div>
                        </div>
                    </div>
                </div>


                <div className='banner-bottom-color' style={{ maxHeight: '70px' }}>
                    <div className={'container'}>
                        <div className='row'>
                            <div className='col-xl-2 d-none d-xl-block pl-0 pr-0'
                                 style={{ display: 'flex', alignItems: 'center' }}>
                                {/* header logo */}
                                <FullMenu />
                            </div>
                            <div className="col-xl-8  d-none d-xl-block">
                                {/* Nav menu */}
                                <NavMenu />
                            </div>
                            <div className="col-xl-2 d-none d-xl-block">

                                {/* Icon group */}
                                {displayAdvent == 1 && width > 1199 &&
                                    <div className='main-menu' style={{ height: "100%" }}>
                                        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                                            <span style={{ display: "inline-block", textAlign: "center", width: "100%" }}>
                                                <Link to={{
                                                    pathname: `${process.env.PUBLIC_URL}/advent-calendar`
                                                }}>
                                                    <span className="christmas-button" style={{ minWidth: "92%" }}>{t('Advent Calendar')}</span>
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* mobile menu */}
                <MobileMenu />
            </div>
        </header >
    );
};

HeaderOne.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    headerPositionClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string
};

export default HeaderOne;
