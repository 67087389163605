import { createSlice } from '@reduxjs/toolkit';
import config from '../../config/config';
const initialState = {
    selectedCountry: config.countryDefault,
};

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
    },
});

export const { setCountry } = countrySlice.actions;

export default countrySlice.reducer;
