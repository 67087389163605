import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import HeaderOne from "../wrappers/header/HeaderOne";
import FooterOne from "../wrappers/footer/FooterOne";
import VoteBadge from "../wrappers/footer/ShopvoteWidget";
import CookieConsent from "react-cookie-consent";
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../helpers/useWindowSize.js';
import { useTranslation } from "react-i18next";
import TrustedShopsWidget from "../wrappers/footer/TrustedShopsWidget";
import useLocaleConfigSelectors from '../helpers/useLocaleConfigSelectors.js';
import { useGetConfigQuery } from '../redux/service/ioonsService.js';
import GTMConsentManager from '../helpers/GTMConsentManager';
import { getIsAdmin } from '../helpers/session.js';
import useLocalStorage from '../helpers/useLocalStorage.js';

const LayoutOne = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass
}) => {
  const location = useLocation();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const [salesBarIsVisible] = useLocalStorage('salesBarVisible', true);
  const [classHeader, setClassHeader] = useState("");
  const {
    getLogoFooter,
    getIoonsMailPicture,
    getShopvoteDisplay: ShopvoteDisplay,
    getTrustedShopDisplay: TrustedShopDisplay,
  } = useLocaleConfigSelectors();
    
    let {getDisplayCountryHeader} = useLocaleConfigSelectors();
    getDisplayCountryHeader = Boolean(getDisplayCountryHeader);
  const { data: configApi, isSuccess } = useGetConfigQuery();
  const [displayAdvent, setDisplayAdvent] = useState();
  useEffect(() => {
    if (isSuccess) {
      if (configApi.displayAdvent) {
        setDisplayAdvent(configApi.displayAdvent);
      }
    }
  }, [configApi]);

  const { updateConsent } = GTMConsentManager();
  const handleAccept = () => {
    document.body.style.overflow = 'auto';
    updateConsent(true);
  };
  const handleDecline = () => {
    document.body.style.overflow = 'auto';
    updateConsent(false);
  };
  const isAdmin = getIsAdmin();
  //const isAdmin = true;
  let class_header = '';
    useEffect(() => {
        // console.log({ isAdmin }, {getDisplayCountryHeader}, { salesBarIsVisible } )
        
        if (isAdmin && getDisplayCountryHeader && salesBarIsVisible) {
            setClassHeader('c-header-spacer-admin-country-sales');
        } else if (isAdmin && getDisplayCountryHeader) {
            setClassHeader('c-header-spacer-admin-country');
        } else if (getDisplayCountryHeader && !isAdmin) {
            setClassHeader('c-header-spacer-country');
        } else if (!getDisplayCountryHeader && isAdmin) {
            setClassHeader('c-header-spacer-admin');
        }
        
    }, [salesBarIsVisible]);

  return (
    <div className="d-flex flex-column min-vh-100" style={{ overflowX: "hidden" }}>
      <Fragment>
        {location.pathname !== `/${t('data-protection')}` && (
          <CookieConsent
            location="top"
            buttonText={t("Let's do it!")}
            enableDeclineButton
            declineButtonText={t('No, Keep it generic.')}
            cookieName="CookieConsentGA"
            cookieValue="granted"
            declineCookieValue="denied"
            onAccept={handleAccept}
            onDecline={handleDecline}
            expires={150}
            overlay
            buttonWrapperClasses="popup-google-content-cookie-consent-wrapper-buttons"
            buttonClasses="popup-google-content-cookie-consent-button-yes"
            declineButtonClasses="popup-google-content-cookie-consent-button"
            containerClasses="popup-google-content-cookie-consent"
            contentClasses="popup-google-content-cookie-consent-text"
          >
            <p className="popup-google-content-cookie-consent-text-title">{t('Ready to Personalize Your Experience?')}</p>
            <p>{t('We use cookies to enhance your experience, provide social media features and analyze traffic.')}</p>
            <p>
              {t('For more details on the use of cookies see our privacy policy.')} <Link to={`${process.env.PUBLIC_URL}/${t('data-protection')}`}>{t('Learn more')}</Link>
            </p>
            <p>{t('We also share information about your interactions on our site with our social networking, publicity and analytics partners. Do you agree to this?')}</p>
          </CookieConsent>
        )}
        <HeaderOne
          layout={headerContainerClass}
          top={headerTop}
          overlayClasses={"sticky-bar.stick"}
          headerPaddingClass={headerPaddingClass}
          headerPositionClass={headerPositionClass}
        />
        <div className={`c-header-spacer ${classHeader}`}></div>
        <div className="d-xl-none bg-gray-2-ioons">
          {/* Icon group */}
          {displayAdvent == 1 && width < 1200 &&
            <div className='main-menu' style={{ padding: "13px 0" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span style={{ display: "inline-block", textAlign: "center", width: "100%" }}>
                  <Link to={{
                    pathname: `${process.env.PUBLIC_URL}/advent-calendar`
                  }}>
                    <span className="christmas-button" style={{ minWidth: "40%" }}>{t('Advent Calendar')}</span>
                  </Link>
                </span>
              </div>
            </div>
          }
        </div>
        {children}
        {TrustedShopDisplay.badge == true && (
          <>
            <TrustedShopsWidget />
          </>
        )}
        {ShopvoteDisplay.badge == true && (
          <>
            <VoteBadge />
          </>
        )}
        <FooterOne
          footerLogo={getLogoFooter}
          mailPicture={getIoonsMailPicture}
          backgroundColorClass="bg-custom-color"
          spaceTopClass="pt-40"
          spaceBottomClass="pb-40"
        />
      </Fragment>
    </div>
  );
};

LayoutOne.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string
};

export default LayoutOne;
