export const FETCH_COUNTY_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchCountySuccess = county => ({
	type: FETCH_COUNTY_SUCCESS,
	payload: county
});

// fetch products
export const fetchCounty = county => {
	return dispatch => {
		dispatch(fetchCountySuccess(county));
	};
};