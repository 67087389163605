import {combineReducers} from "redux";
import {createMultilanguageReducer} from "redux-multilanguage";
import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import globalCartReducer from "./globalCartReducer.js";
import countyReducer from './countyReducer.js';
import { ioonsApi } from '../service/ioonsService.js';
import { fastApi } from '../service/fastApiService.js';
import localeConfigReducer from '../slices/locale_configSlice.js';
import countrySlice from '../slices/countrySlice.js';

const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({currentLanguageCode: "en"}),
    currencyData: currencyReducer,
    productData: productReducer,
    cartData: cartReducer,
    wishlistData: wishlistReducer,
    compareData: compareReducer,
    globalCartData: globalCartReducer,
    countyData: countyReducer,
    [ioonsApi.reducerPath]: ioonsApi.reducer,
    [fastApi.reducerPath]: fastApi.reducer,
    localeConfig: localeConfigReducer,
    country: countrySlice,
});

export default rootReducer;
