import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { load, save } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension";
import config from 'react-global-configuration';
import storeToolkit from './redux/store';
import { ioonsApi } from './redux/service/ioonsService.js';
import { fastApi } from './redux/service/fastApiService.js';
import { current } from "@reduxjs/toolkit";
import GTMConsentManager from './helpers/GTMConsentManager';
import { getCookieConsentValue } from "react-cookie-consent";

// Set up Google Tag Manager script
window.dataLayer = window.dataLayer || [];
window.gtag = function () { window.dataLayer.push(arguments); }; // Define gtag globally
gtag('consent', 'default', {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied'
});
(function () {
  //var gtmId = 'GTM-5B8RLRZ'; // Replace this with dynamic value if needed
  var gtmId = config.get("gtmId");
  var gtagScript = document.createElement('script');
  gtagScript.async = true;
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gtmId;
  document.head.appendChild(gtagScript);

  gtagScript.onload = function () {
    gtag('js', new Date());
    gtag('config', gtmId);
  };
})();
const { updateConsent } = GTMConsentManager();
// Check for CookieConsentGA cookie and update consent if granted
if (getCookieConsentValue("CookieConsentGA") === 'granted') {
  updateConsent(true);
}

const currentDomain = window.location.hostname;

if (process.env.REACT_APP_DOMAIN_TYPE === 'hausfabrik') {
  require('./assets/scss/styles_hausfabrik.scss');
} else {
  require('./assets/scss/style.scss');
}

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(fastApi.middleware, ioonsApi.middleware, thunk, save()),
  ));

const store1 = storeToolkit;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// Dynamically change favicon based on domain type
if (process.env.REACT_APP_DOMAIN_TYPE === 'hausfabrik') {
  document.querySelector("link[rel='icon']").setAttribute("href", `${process.env.PUBLIC_URL}/favicon_hausfabrik.ico`);
} else {
  document.querySelector("link[rel='icon']").setAttribute("href", `${process.env.PUBLIC_URL}/favicon.ico`);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
