const config = {
/*      apiUrl: "https://backoffice.24.ioons.de",
        fastApi: "https://fa.ioons.de",
        token: "E3807535EBB4FCD6DBF2291BB",
        mode: 'development',
        gtmId: 'GTM-5B8RLRZ',
        language: 'de',
        locale: "de_DE",
        domain: "localhost",
*/
        apiUrl: "https://backoffice.hausfabrik.ro",
        fastApi: "https://fa.hausfabrik.ro",
        token: "47DC9A3A01D37D65FD3B72BE5",
        mode: 'development',
        gtmId: 'GTM-W4SSLR2',
        language: 'ro',
        locale: "ro_RO",
        domain: "hausfabrik.ro",
        zendeskKey: '1dc2b18f-c3aa-4839-b9ac-871feb527078',
stripePublicKey: "",
	amazonStoreId: '',
	amazonMerchantId: '',
	amazonPublicKeyId: '',
sandbox: 'false',
        locale_config: [
                'lang_id',
                'lang_locale',
                'lang_url',
                'country_id',
                'tax_tax_id',
                'tax_tax_title',
                'tax_serialized_requirements',
   	        'store_name',
		'sal',
		'anpc',
		'display_buttons_ANPC',
		'default_product_image',
                'lang_code',
                'currency_currency_code',
                'loading_gif',
                'currency_currency_symbol',
                'site_key',
                'logo_image',
                'widerrufsformular_pdf_link',
                "content_ids",
                "logo_footer",
                "logo_header",
                "home_team_img",
                "homepage_products",
                "hero_slider_twelve",
                "vorkasse_info",
                "country_title",
                "ioons_mail_picture",
                "ioons_facebook_url",
                "ioons_instagram_url",
                "ioons_youtube_url",
                "shopvote_shop_id",
                "trusted_shop_id",
                "shopvote_display",
                "trustedshop_display",
                "phone_regex",
                "footer_image_1",
                "footer_image_2",
                "footer_image_3",
                "footer_image_4",
                "feature_icon_eight",
                "testimonial_one",
		"vat_min_length",
		"textInCart",
"multiplier",
"captcha_site_key",
"content_with_images",
"best_sellers_products_homepage_ids",
"brand_logos_homepage",
"banners_homepage",
"country_visible",
"config_min_price",
"country_flag",
		"display_country_header",
		"is_phone_required"
        ],
        refetchTimingShort: 60,
        refetchTimingLong: 30,
keywordGroupString: "badges",
countryDefault: {id: 146, title: 'Romania', tax_title: '19% Tva'},
};

export default config;


